import { call, takeEvery } from 'redux-saga/effects';
import { MerchantSearchConstants } from 'constants/merchantSearch';
import { request } from 'components/Request';
import { appConfig } from 'config/appConfig';

function* getMerchants(action) {
  yield call(request({
    type: MerchantSearchConstants.GET_MERCHANTS,
    method: 'GET',
    url: action.payload.url,
    apiUrl: action.payload.apiUrl,
    headers: appConfig.apiKey ? { 'x-api-key': appConfig.apiKey } : null,
  }), action);
}

const MerchantSearchSaga = function* () {
  yield takeEvery(MerchantSearchConstants.GET_MERCHANTS, getMerchants);
};

export default MerchantSearchSaga;
