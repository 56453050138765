import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactLoading from 'react-loading';

import { parseUrlParams } from 'utils/parseUrlParams';
import get from 'lodash/get';

import {
  checkinAction,
  checkPreviousAction,
} from 'actions/workflow';

const CheckingApplication = (props) => {
  useEffect(() => {
    const { workflow } = props;
    const state = get(workflow, 'data');
    if (state) {
      const routeUrl = state.url;
      props.history.push(routeUrl);
    }
  }, []);

  useEffect(() => {
    const params = parseUrlParams(window.location.search);
    if (!params.key) {
      props.history.push('/');
    } else {
      const payload = {};

      if (params.offername) {
        payload.offerName = params.offername;
      }

      if (params.tpoffer === 'true') {
        payload.tpoffer = true;
      }

      if (params.unifiedoffer === 'true') {
        payload.unifiedoffer = true;
      }

      if (params.fslkey) {
        payload.fslKey = params.fslkey;
      }

      const maxTry = 10;
      let currentTry = 0;
      const checkInCall = () => {
        props.checkPreviousAction({
          data: payload,
          url: `workflows/dtc/${params.key}/checkin`,
          success: (res) => {
            if (res.data && res.data !== null) {
              const routeUrl = res.data.url;
              props.history.push(routeUrl);
            } else if (maxTry > currentTry && params.offername) {
              currentTry++;
              setTimeout(() => { checkInCall(); }, 1000 * 10);
            }
          },
          // eslint-disable-next-line
          fail: (error) => {
            console.log(error);
            props.history.push(`/${props.match.params.workflowtype}/application?key=${params.key}`);
          },
        });
      };

      checkInCall();
    }
  }, []);

  return (
    <form>
      <div className="loading-container">
        <ReactLoading color="#3989E0" width={100} height={100} type="spin" />
      </div>
    </form>
  );
};

CheckingApplication.propTypes = {
  history: PropTypes.object.isRequired,
  checkPreviousAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
};

CheckingApplication.defaultProps = {
};

export default connect(
  (state) => ({
    auth: state.auth,
    workflow: state.workflow,
  }),
  {
    checkinAction,
    checkPreviousAction,
  }
)(CheckingApplication);
