import React from 'react';
import cn from 'classnames';

import Header from 'components/Header';
import FormGroupLabel from 'components/FormGroupLabel';
import Footer from 'components/Footer';
import { Button } from 'components/Button';
import { GridContainer, Grid, Column } from 'components/Layout';
import style from './style.scss';

const CreditAppDoc = () => (
  <>
    <Header />
    <GridContainer>
      <GridContainer className="fluid page-sign-load">
        <Grid>
          <Column className="small-12 large-9">
            <iframe
              src="https://na2.docusign.net/Signing/StartInSession.aspx?t=112b4e8d-574a-4af8-a776-22bd5ea1c8ad"
              width="100%"
              height="1200"
              title="docusign"
              className={style.docuSign}
            />
          </Column>
          <Column className={cn('small-12 large-3', style.loanInfo)}>
            <FormGroupLabel value="Sign Credit Loan Document" />
            <p>Borrower must review and e-sign the Credit Loan Document to the left to finalize their loan.</p>
            <h5>Your Approved Loan</h5>
            <Grid className="grid-margin-x">
              <Column className="small-7">
                <p className="p-small">Estimated Monthly Payment</p>
              </Column>
              <Column className="small-5 text-right">
                <h6 className={style['monthly-payments']}>$80.86</h6>
              </Column>
            </Grid>
            <Grid className="grid-margin-x">
              <Column className="small-7">
                <p className="p-small">Amount Financed</p>
              </Column>
              <Column className="small-5 text-right">
                <h6>$2,000.00</h6>
              </Column>
            </Grid>
            <Grid className="grid-margin-x">
              <Column className="small-7">
                <p className="p-small">Loan Term</p>
              </Column>
              <Column className="small-5 text-right">
                <h6>60 Months</h6>
              </Column>
            </Grid>
            <Grid className="grid-margin-x">
              <Column className="small-7">
                <p className="p-small">Interest Rate</p>
              </Column>
              <Column className="small-5 text-right">
                <h6>27.45%</h6>
              </Column>
            </Grid>
            <Grid className="grid-margin-x">
              <Column className="small-12 padded-bottom">
                <p className="p-small">
                  <em>Special Promotion:</em>
                  {' '}
                  No Interest on principal if your loan is paid in full within the first six months
                </p>
              </Column>
              <Column className="small-12">
                <div className="expanded button-group">
                  <Button
                    className={cn('button secondary', style.eSignLater)}
                    onClick={() => {}}
                  >
                    eSign Later
                  </Button>
                </div>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </GridContainer>
    </GridContainer>
    <Footer />
  </>
);

CreditAppDoc.propTypes = {

};

CreditAppDoc.defaultProps = {

};

export default CreditAppDoc;
