import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col,
  Container,
  Modal,
  Progress,
  Row,
} from 'reactstrap';
import get from 'lodash/get';

import { resumeApplicationAction } from 'actions/workflow';
import imgError from 'assets/images/error.svg';
import { parseUrlParams } from 'utils/parseUrlParams';

const CreditFreeze = (props) => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const params = parseUrlParams(window.location.search);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!params.key || !props.workflow) {
      props.history.push(`/applications/${props.match.params.workflowtype}/application`);
    } else if (!get(props.workflow, 'data.data', get(props.workflow, 'state.data'))) {
      props.history.push(`/applications/${props.match.params.workflowtype}/checkin?key=${params.key}`);
    }
  }, [props.workflow]);

  const resumeApplication = (e) => {
    e.preventDefault();
    setIsProgressVisible(true);
    dispatch(resumeApplicationAction({
      key: params.key,
      activity: 'continuefromcreditfreeze',
      workflowType: 'dtc',
      success: (res) => {
        setIsProgressVisible(false);
        const route = get(res, 'state.url', get(res, 'data.url'));
        if (route) {
          props.history.push(route);
        }
      },
      fail: (error) => {
        setIsProgressVisible(false);
        setIsPopupVisible(true);
        if (error?.response?.data && error.response.data.errorMessages && error?.response?.data.errorMessages.length > 0) {
          setErrorMsg(error.response.data.errorMessages[0]);
        }
        console.log('error', error);
      },
      onUploadProgress: (progressEvent) => {
        setUploadingPercentage(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
      },
    }));
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setErrorMsg('');
  };

  return (
    <div className="page-preapproved narrow">
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} className="text-center mb-2 mt-2 mt-md-4">
            <p className="mb-0">
              <b>Application ID:</b>
              {' '}
              {get(props.workflow, 'attributes.entityId')}
            </p>
            <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
            <h2 className="mb-0">Your Credit Is Frozen</h2>
            <p className="mb-0">The following actions are needed so that a decision can be made on your application:</p>

            <Row>
              <Col>
                <h4 className="mb-1">
                  To remove the freeze from your credit report, please visit the
                  {' '}
                  <a target="_blank" rel="noopener noreferrer" href="https://service.transunion.com/dss/login.page?">TransUnion Service Center</a>
                  {' '}
                  or call TransUnion at
                  {' '}
                  <a href="tel:+18889098872">888-909-8872</a>
                  .
                </h4>
                <h4 className="mb-4">
                  After the removal of the credit freeze, please click on this
                  {' '}
                  {/* eslint-disable-next-line */}
                  <a href="#" onClick={resumeApplication}>link</a>
                  {' '}
                  to continue your application.

                </h4>
                <hr />
                <p className="p-large">You must complete the above actions within 30 days; otherwise, we will be unable to proceed with this credit application.</p>
              </Col>
            </Row>
            {
              isPopupVisible && (
                <Modal>
                  <div className="card-content error-modal security-error">
                    <img src={imgError} alt="" />
                    <p>{errorMsg || 'Please try again.'}</p>
                    <div onClick={handleClosePopup} className="btn btn-red">OK</div>
                  </div>
                </Modal>
              )
            }
            {
              isProgressVisible && <Progress percent={uploadingPercentage} />
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CreditFreeze.propTypes = {
  history: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

CreditFreeze.defaultProps = {
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      workflow: state.workflow,
    })
  )
)(CreditFreeze);
