import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components/Heading';
import { Button } from 'components/Button';

import thumbup from 'assets/icons/thumbs-up.svg';
import style from './style.scss';

const Complete = (props) => (
  <form className={cn(style.App, props.className)} onSubmit={() => {}}>
    <section className={cn('grid-container fluid', style['borrowers-message'])}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x max-limited">
          <div className="cell small-12 large-8 large-offset-2">
            <img src={thumbup} alt="thumb up" />
            <Heading
              heading="That’s it. You’re all set!"
              subHeading={(
                <>
                  We just emailed you all the details about your LendingUSA loan, along
                  <br />
                  with a copy of your signed loan agreement. If you have any questions
                  <br />
                  talk to your merchant or call 800-994-6177.
                </>
              )}
              className={style.heading}
              size="p-xlarge"
            />
            <Button className={cn('button large green', style.button)}>Return to Homepage</Button>
          </div>
        </div>
      </div>
    </section>
  </form>
);

Complete.propTypes = {
  className: PropTypes.string,
};

Complete.defaultProps = {
  className: '',
};

export default Complete;
