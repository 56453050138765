import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const currencyMask = createNumberMask({
  prefix: '$ ',
  allowDecimal: true,
  integerLimit: 10,
});

export const confirmAmountOfSaleMask = createNumberMask({
  prefix: '$ ',
  allowDecimal: true,
  integerLimit: 5,
  maxValue: 100,
  minValue: 0,
});

export function unmask(val) {
  return val.replace(/[$, ]+/g, '');
}

export function numberUnmask(val) {
  return val.match(/\d+/g).join('');
}

export const routingNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const accountMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  integerLimit: 17,
  allowLeadingZeroes: true,
});

export function floatUnmask(val) {
  const matches = val.match(/[\d.]+/g) || [];
  return matches.join('');
}

export function removeSpace(str) {
  return str.replace(/\s+/g, '');
}
