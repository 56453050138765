import React from 'react';
import PropTypes from 'prop-types';
import FormRow from '../Form/FormRow';
import FormColumn from '../Form/FormColumn';
import './style.scss';

const ApplicationId = ({ id, inHeadline, color, style }) => (
  <FormRow>
    <FormColumn>
      <p
        className="appId"
        style={{
          ...inHeadline ? { marginLeft: 20, marginTop: 20 } : { marginBottom: 10 },
          ...style,
          color,
        }}
      >
        <b>Application ID:</b>
        {' '}
        {id}
      </p>
    </FormColumn>
  </FormRow>
);

ApplicationId.propTypes = {
  id: PropTypes.string.isRequired,
  inHeadline: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
};

ApplicationId.defaultProps = {
  inHeadline: false,
  style: {},
  color: undefined,
};

export default ApplicationId;
