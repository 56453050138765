import React from 'react';
import PropTypes from 'prop-types';

const FormColumn = ({ children, className, offset }) => (
  <div className={`col${offset ? `-${offset}` : ''} ${className}`}>
    {children}
  </div>
);

FormColumn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  offset: PropTypes.number,
};

FormColumn.defaultProps = {
  className: '',
  offset: 0,
};

export default FormColumn;
