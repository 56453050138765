import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Button } from 'components/Button';
import InfiniteScroll from 'react-infinite-scroller';
import get from 'lodash/get';
import { nextAction } from 'actions/workflow';
import { merchantSearch } from 'actions/merchantSearch';
import Loader from 'components/Loader';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { parseUrlParams } from 'utils/parseUrlParams';
import { uid } from 'react-uid';

const purposeTypes = [
  { value: 'chiropractic', label: 'Chiropractic' },
  { value: 'cosmeticsurgery', label: 'Cosmetic Surgery' },
  { value: 'funeral', label: 'Funeral' },
  { value: 'petretail', label: 'Pet Retail' },
  { value: 'pettraining', label: 'Pet Training' },
  { value: 'taxdebtrelief', label: 'Tax Debt Relief' },
  { value: 'debtconsolidation', label: 'Debt Consolidation' },
  { value: 'timeshareexit', label: 'Timeshare Exit' },
];

const DTCFindMerchant = (props) => {
  const [noMerchant, setNoMerchant] = useState(false);
  const [validZip, setValidZip] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [merchantSelected, setMerchantSelected] = useState(false);
  const [selected, setSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [purposeCode, setPurposeCode] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [merchants, setMerchants] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchZipcode, setSearchZipcode] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [infiniteId, setInfiniteId] = useState(uid({}));
  const limit = 10;

  useEffect(() => {
    const { history, workflow } = props;
    const params = parseUrlParams(window.location.search);

    if (get(workflow, 'data.data') === undefined) {
      history.push(`/applications/dtc1/checkin?key=${params.key || ''}`);
    }

    setZipcode(get(workflow, 'data.data.zipCode', ''));
    setPurposeCode(get(workflow, 'data.data.purpose', ''));
    setValidZip(true);
  }, []);

  const handleNoMerchantCheck = () => {
    setNoMerchant(!noMerchant);
    setShowResults(false);
    setMerchantSelected(!noMerchant);
  };

  const isFormValid = () => !noMerchant && (purposeCode && validZip);

  const handleSearch = (evt) => {
    evt.preventDefault();

    setShowResults(false);
    setOffset(0);
    setIsSearching(true);
    setSearchZipcode(zipcode);
    setSelected(null);
    setMerchantSelected(false);
    setInfiniteId(uid({ date: (new Date()).getTime() }));

    const queryNames = ['DisplayNameOrMerchantName', 'PurposeCode', 'offset', 'limit'];
    const queryParams = [displayName, purposeCode, 0, limit].map((item, index) => {
      if (item !== '' && item !== undefined && item !== null) {
        return `&${queryNames[index]}=${item}`;
      }
      return '';
    }).join('').slice(1);
    props.merchantSearch({
      url: `/merchants-view/search/zipcodes/${zipcode}?${queryParams}`,
      success: (res) => {
        setMerchants([...res.data]);
        setOffset(res.pagination.limit);
        setHasMore(res.pagination.limit < res.pagination.total);
        setShowResults(true);
        setIsSearching(false);
      },
      fail: (error) => {
        console.log(error);
        setIsSearching(false);
      },
    });
  };

  const loadMoreMerchants = (page) => {
    const queryNames = ['DisplayNameOrMerchantName', 'PurposeCode', 'offset', 'limit'];
    const queryParams = [displayName, purposeCode, offset, limit].map((item, index) => {
      if (item !== '' && item !== undefined && item !== null) {
        return `&${queryNames[index]}=${item}`;
      }
      return '';
    }).join('').slice(1);

    isFormValid() && props.merchantSearch({
      url: `/merchants-view/search/zipcodes/${zipcode}?${queryParams}`,
      success: (res) => {
        setMerchants([
          ...merchants,
          ...res.data,
        ]);
        setOffset(offset + res.pagination.returned);
        setHasMore(page * res.pagination.limit < res.pagination.total);
        setSearchZipcode(zipcode);
      },
      fail: (error) => {
        console.log(error);
      },
    });
  };

  const handleSelection = (selection) => {
    setSelected(selection);
    setMerchantSelected(true);
  };

  const handleInputChange = (e) => {
    e.preventDefault();

    switch (e.target.name) {
      case 'zipcode':
        setValidZip(e.target.value.length === 5);
        setZipcode(e.target.value);
        break;
      case 'displayName':
        setDisplayName(e.target.value);
        break;
      case 'purposeCode':
        setPurposeCode(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleContinueButton = (e) => {
    e.preventDefault();
    const merchantId = get(merchants, `${selected}.merchantId`);
    const params = parseUrlParams(window.location.search);
    let formData = {};
    setIsLoading(true);
    if (noMerchant) {
      formData = {};
    } else {
      formData = {
        merchantId,
      };
    }
    props.nextAction({
      data: formData,
      url: `/workflows/dtc/${params.key}/next`,
      success: (response) => {
        setIsLoading(false);
        const routeUrl = response.data && response.data.url;
        props.history.push(routeUrl);
      },
      fail: (error) => {
        props.history.push({
          pathname: '/borrowers/dtc1/general-error-page',
          search: '',
          state: {
            data: error.data,
          },
        });
      },
    });
  };

  return (
    <div className="page-findmerchant pb-4" style={{ position: 'relative' }}>
      <Container fluid>
        <Row className="mt-md-3 justify-content-center">
          <Col md={10} lg={9} xl={7}>
            <p className="mb-1">
              <b>Application ID:</b>
              {' '}
              {get(props.workflow, 'attributes.entityId')}
            </p>
          </Col>
        </Row>
        <Row className="mt-md-3 mb-2 mb-md-4 justify-content-center">
          <Col md={10} lg={9} xl={7}>
            <h1 className="mb-1">Find A Merchant</h1>
            <p className="mb-1">
              <strong className="text-primary">Step 2 of 3</strong>
&nbsp; Locate a merchant near you that accepts LendingUSA Financing.
            </p>
            <p className="mb-0" style={{ fontSize: 14 }}>
              <strong>NOTE:</strong>
              {' '}
              Loans may only be available through participating merchants. If you do not have a merchant
              {' '}
              <strong>OR</strong>
              {' '}
              if you cannot find your merchant using our locator, please select the box below and click &quot;Check My Rate&quot;. If pre-approved, you will be able to select a participating merchant at a later time.
            </p>
          </Col>
        </Row>

        <Row className="mb-4 pb-1 justify-content-center">
          <Col md={10} lg={9} xl={7}>
            <Card className="h-100 mb-0 border-primary pull-30">
              <CardBody>
                <h5 className="mb-3">
                  Please enter merchant name
                  {' '}
                  <i className="text-primary">or</i>
                  {' '}
                  merchant industry
                  {' '}
                  <i className="text-primary">near</i>
                  {' '}
                  your location
                </h5>
                <Form className="inline-fill mb-2">
                  <Row form>
                    <Col md={4}>
                      <FormGroup className="mb-0 pb-0">
                        <Label for="displayName">Merchant Name</Label>
                        <Input type="text" name="displayName" id="MerchantName" onChange={handleInputChange} />
                      </FormGroup>
                    </Col>
                    <Col md={1} className="connector">OR</Col>
                    <Col md={4}>
                      <FormGroup className="mb-0 pb-0">
                        <Label for="purposeCode">Industry</Label>
                        <Input type="select" name="purposeCode" id="purposeCode" onChange={handleInputChange} value={purposeCode} defaultValue={purposeCode}>
                          <option value="">Select One...</option>
                          {purposeTypes.map((purposeType, index) => (<option key={index} value={purposeType.value}>{purposeType.label}</option>)) }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={1} className="connector">NEAR</Col>
                    <Col md={2}>
                      <FormGroup className="mb-0 pb-0">
                        <Label for="zipcode">Zip Code</Label>
                        <Input
                          type="tel"
                          name="zipcode"
                          id="zipcode"
                          maxLength="5"
                          onChange={handleInputChange}
                          defaultValue={zipcode}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col md="auto">
                    <CustomInput
                      type="checkbox"
                      className="mb-2"
                      id="noMerchant"
                      name="noMerchant"
                      label="I don't have a specific merchant or I cannot find my merchant"
                      onChange={handleNoMerchantCheck}
                    />
                  </Col>
                  <Col md={4} className="ml-auto">
                    <Button
                      color="primary"
                      className="w-100"
                      type="submit"
                      disabled={!isFormValid()}
                      onClick={handleSearch}
                      isLoading={isSearching}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row id="FindMerchantResults" className={`mt-3 mb-4 justify-content-center ${showResults ? 'd-flex' : 'd-none'}`}>
          <Col md={10} lg={9} xl={7}>
            <h3 className="pb-1 border-bottom">Search Results</h3>
            <InfiniteScroll
              pageStart={1}
              loadMore={loadMoreMerchants}
              hasMore={hasMore}
              loader={<Loader className="mt-3" />}
              initialLoad={false}
              key={infiniteId}
            >
              {
                merchants.length === 0 && (
                  <Row className="results-item">
                    <Col xs={12} className="text-cente pt-2">
                      <p>We did not find any results with your search criteria. Please double check the Merchant Name, Industry and/or Zip Code and try again.</p>
                    </Col>
                  </Row>
                )
              }
              {
                merchants.map((item, index) => (
                  <Row className={`pt-2 pb-2 results-item ${selected === index ? 'selected' : ''}`} key={`merchants-${index}`} onClick={handleSelection.bind(null, index)}>
                    <Col xs={6} sm={4} className="d-flex align-items-center pl-0">
                      <div className="list-count">{index + 1}</div>
                      <h4 className="mb-0">{item.displayName}</h4>
                    </Col>
                    <Col xs={6} sm={4} className="d-flex align-items-center">
                      <small>
                        {item.primaryBusinessAddress.address}
                        {
                          item.primaryBusinessAddress.line2 ? `, ${item.primaryBusinessAddress.line2}` : ''
                        }
                        .
                        <br />
                        {item.primaryBusinessAddress.city}
                        ,
                        {' '}
                        {item.primaryBusinessAddress.state}
                        {' '}
                        {item.primaryBusinessAddress.zipCode}
                        <br />
                        {formatPhoneNumber(item.phone)}
                      </small>
                    </Col>
                    <Col xs={6} sm={2} className="d-flex align-items-center ml-auto mb-2 mb-md-0 mt-1 mt-md-0">
                      <small>
                        <strong>
                          {Number(item.distance).toFixed(2)}
                          {' '}
                          mi
                        </strong>
                        &nbsp;
                        <br className="d-none d-md-block" />
                        from
                        {' '}
                        {searchZipcode}
                      </small>
                    </Col>
                    <Col sm={2} className="d-flex align-items-center pl-0 pr-0 select-button-wrapper">
                      {
                        selected === index
                          ? <Button tag="div" size="sm" color="success" className="w-100">Selected</Button>
                          : <Button tag="div" size="sm" color="primary" className="w-100">Select</Button>
                      }
                    </Col>
                  </Row>
                ))
              }
            </InfiniteScroll>

          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10} lg={9} xl={7} className="text-center">
            <p style={{ fontSize: '.8rem' }}>Your loan may have a No Interest on Principal Option Promotion included. This promotion can save you money if you pay off the principal amount of the loan in full within the Promotional Period (&#34;Promotional Period&#34;). During the Promotional Period you will be responsible for making all of your monthly payments and your loan will accrue interest on a monthly basis. If you pay off your loan within the Promotional Period, the monthly payments that you have made during this period, which includes accrued interest, will be deducted from the principal amount of the loan. Length of Promotional Periods vary, please review your loan agreement for full details.</p>
            <p style={{ fontSize: '.8rem' }}>
              To check the rates you qualify for, LendingUSA does a soft credit pull that will not impact your credit score. However, if you choose to continue your application, your full credit report will be requested from one or more consumer reporting agencies, which is considered a hard credit pull.
            </p>
          </Col>
        </Row>
      </Container>

      <div id="ContinueButtonContainer" className={`${merchantSelected ? 'd-flex' : 'd-none'}`}>
        <Button
          id="ContinueButton"
          size="lg"
          color="primary"
          onClick={handleContinueButton}
          isLoading={isLoading}
        >
          Check My Rate
          {' '}
          <img src="/icons/arrow-right-lg.svg" alt="" />
        </Button>
      </div>

    </div>
  );
};

DTCFindMerchant.propTypes = {
  history: PropTypes.object.isRequired,
  nextAction: PropTypes.func.isRequired,
  merchantSearch: PropTypes.func.isRequired,
  workflow: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    workflow: state.workflow,
  }),
  {
    nextAction,
    merchantSearch,
  }
)(DTCFindMerchant);
