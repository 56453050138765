export const appConfig = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.lendingusa.com/v1',
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || 'us-west-2_69LJvk8ry',
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '7i8o0e622b3m3g0mu464ehb2k3',
  region: process.env.REACT_APP_COGNITO_REGION || 'us-west-2',
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID || 'us-west-2:97b9ced9-fa08-4bc3-bb8d-7472e93803cc',
  limit: process.env.REACT_APP_GRID_PAGE_LIMIT || 10,
  smartyStreetsWebsiteKey: '32906421591607929',
  smartyStreetEnforce: true,
  isSetValueVisible: false,
  apiKey: 'h137FF5Fuq6rNyGnaXsfc8WS9xTH3DUn7Ok72vBS',
  dtcOfferCodeUrl: 'https://lendingusa.com/dtc-offer-page',
  pageSenseId: process.env.PAGESENSE_ID || '2580577644d543e48d41f5b7ecfe8b61',
};
