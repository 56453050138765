import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import fetch from './fetch';
import workflow from './workflow';
import application from './application';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  fetch,
  workflow,
  application,
});

export default createRootReducer;
