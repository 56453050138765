import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, CustomInput } from 'reactstrap';

const PopoverCheckbox = (props) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverContent, setPopoverContent] = useState('');
  const { onChange, isChecked, className, errorMessage, id, label, name } = props;

  const handleChange = () => {
    onChange(!isChecked);
  };

  const togglePopover = (content) => {
    setPopoverContent(content);
    setShowPopover(!showPopover);
  };

  const combinedLabelText = (
    <div>
      <p>
        <span onClick={() => togglePopover(label[0].content)} className="text-primary text-link">{label[0].text}</span>
        {' '}
        and
        {' '}
        <span onClick={() => togglePopover(label[1].content)} className="text-primary text-link">{label[1].text}</span>
        {' '}
        and to the
        {' '}
        <span onClick={() => togglePopover(label[2].content)} className="text-primary text-link">{label[2].text}</span>
      </p>
    </div>
  );

  return (
    <div className="popover-container">
      <Popover isOpen={showPopover} placement="top" target={id} className="popover" toggle={() => setShowPopover(!showPopover)}>
        <div className="scrollBar">
          {popoverContent}
        </div>
      </Popover>
      <CustomInput
        className={className}
        type="checkbox"
        id={id}
        label=""
        name={name}
        onChange={handleChange}
        checked={isChecked}
      />
      <div>{combinedLabelText}</div>
      {!!errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

PopoverCheckbox.propTypes = {
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
};

PopoverCheckbox.defaultProps = {
  onChange: () => {},
  isChecked: false,
  className: '',
  errorMessage: '',
  label: [],
  name: '',
};

export default PopoverCheckbox;
