import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';

const EvenDeclined = (props) => {
  const isAppReceived = useMemo(() => get(props.workflow, 'data.data.applicationStatusName', '') === 'AppReceived', [props.workflow]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = parseUrlParams(window.location.search);

    const { history } = props;

    if (!params.key) {
      history.push(`/applications/${props.match.params.workflowtype}/application`);
    } else if (!props.workflow || Object.keys(props.workflow).length === 0) {
      history.push(`/applications/${props.match.params.workflowtype}/checkin?key=${params.key}`);
    }
  }, []);

  return (
    <div className="page-preapproved narrow">
      <Container>
        <Row className="mt-md-3 mb-2 mb-md-4">
          <Col lg={{ size: 8, offset: 2 }}>
            <p className="mb-1">
              <b>Application ID:</b>
              {' '}
              {get(props.workflow, 'attributes.entityId')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
            <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
            <h2 className="mb-2">
              {!isAppReceived
                ? 'Unfortunately, you were not pre-approved for any offers at this time.'
                : 'Unfortunately, there are currently no loan programs available that match your criteria.'}
            </h2>
            <p className="mb-4">You will receive an adverse action notice within 30 days that will provide you with the specific reason(s) as to why your application was not approved.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

EvenDeclined.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
};

EvenDeclined.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
  workflow: state.workflow,
}))(EvenDeclined);
