import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { parseUrlParams } from 'utils/parseUrlParams';
import { getKeyAction as getKeyActionMapped } from 'actions/workflow';

const GuardedRoute = ({ component: Component, getKeyAction, ...rest }) => {
  const params = parseUrlParams(window.location.search);
  if (!params.key && params.applicationid) {
    const queryParams = new URLSearchParams(window.location.search);
    getKeyAction({
      url: `workflows/application/${params.applicationid}/workflow/dtc1/key`,
      success: (response) => {
        queryParams.delete('applicationId');
        queryParams.set('key', response.key);
        window.location.href = `${window.location.pathname}?${queryParams.toString()}`;
      },
    });
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Component {...props} />
        )}
      />
    );
  }
};

GuardedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  getKeyAction: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    getKeyAction: getKeyActionMapped,
  }
)(GuardedRoute);
