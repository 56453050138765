import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const GridContainer = ({ className, children }) => (
  <div className={cn('grid-container', className)}>
    {children}
  </div>
);

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

GridContainer.defaultProps = {
  className: '',
};

export default GridContainer;
