import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

export const VerifyButton = ({ onClick, isVerified, isLoading }) => {
  const [buttonHovered, setButtonHovered] = useState(false);

  const handleMouseEnter = () => {
    setButtonHovered(true);
  };

  const handleMouseLeave = () => {
    setButtonHovered(false);
  };

  return (isVerified ? (
    <div
      className={cn('smarty-tag-check btn-verify-address btn btn-success btn-sm')}
      style={{ width: 115 }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      ✓&nbsp;&nbsp;
      <span className="verifyText">
        { buttonHovered ? 'Undo' : 'Verified' }
      </span>
    </div>
  ) : (
    <>
      {
        isLoading && (
          <div className="smarty-ui-loading">
            <div title="Loading..." className={cn('smarty-dots', 'smarty-addr-29102')} />
          </div>
        )
      }
      <div className={cn('smarty-tag-check btn-verify-address btn btn-primary btn-sm')} onClick={onClick}>
        <span className="verifyText">Verify</span>
      </div>
    </>
  ));
};

VerifyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isVerified: PropTypes.bool,
  isLoading: PropTypes.bool,
};

VerifyButton.defaultProps = {
  isVerified: false,
  isLoading: true,
};

export default VerifyButton;
