import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactLoading from 'react-loading';

import { parseUrlParams } from 'utils/parseUrlParams';
import get from 'lodash/get';

import {
  checkinAction,
  checkPreviousAction,
} from 'actions/workflow';

const Redirection = (props) => {
  useEffect(() => {
    const { history } = props;
    const params = parseUrlParams(window.location.search);

    if (!params.token) {
      history.push('/');
    } else {
      const url = `/workflows/dtc/redirection/token/${params.token}`;

      const checkInCall = () => {
        props.checkPreviousAction({
          data: {},
          url,
          success: (res) => {
            history.push(get(res, 'data.url'));
          },
          fail: (error) => {
            if (get(error, 'status') === 400 && get(error, 'data.message') === 'This link is no longer valid') {
              history.push(`/${props.match.params.workflowtype}/not-valid?key=${params.key}`);
            } else {
              history.push(`/${props.match.params.workflowtype}/application?key=${params.key}`);
            }
          },
        });
      };

      checkInCall();
    }
  }, []);

  return (
    <form>
      <div className="loading-container">
        <ReactLoading color="#3989E0" width={100} height={100} type="spin" />
      </div>
    </form>
  );
};

Redirection.propTypes = {
  history: PropTypes.object.isRequired,
  checkPreviousAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

Redirection.defaultProps = {
};

export default connect(
  (state) => ({
    auth: state.auth,
    workflow: state.workflow,
  }),
  {
    checkinAction,
    checkPreviousAction,
  }
)(Redirection);
