import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import get from 'lodash/get';

import { parseUrlParams } from 'utils/parseUrlParams';
import { updateOffer } from 'actions/workflow';

const OfferClicked = (props) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const params = parseUrlParams(window.location.search);

    if (!params.key) {
      props.history.push('/');
    } else {
      const verifyOffer = () => {
        props.updateOffer({
          data: {
            offerName: decodeURIComponent(params.offername),
          },
          url: `/workflows/${props.match.params.workflowtype}/${params.key}/step/EvenOfferClicked`,
          success: (res) => {
            if (get(res, 'data.redirectUrl')) {
              window.location.href = get(res, 'data.redirectUrl');
            }
          },
          fail: (err) => {
            setErrorMessage(get(err, 'data.failure.errors.0.message', 'An error occurred'));
          },
        });
      };

      verifyOffer();
    }
  }, []);

  return (
    <form>
      <div className="loading-container">
        {errorMessage ? (
          <p style={{ color: '#d71f28', fontSize: 18 }}>{errorMessage}</p>
        ) : (
          <ReactLoading color="#3989E0" width={100} height={100} type="spin" />
        )}
      </div>
    </form>
  );
};

OfferClicked.propTypes = {
  history: PropTypes.object.isRequired,
  updateOffer: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

OfferClicked.defaultProps = {
};

export default connect(
  (state) => ({
    auth: state.auth,
    workflow: state.workflow,
  }),
  {
    updateOffer,
  }
)(OfferClicked);
