/* eslint-disable react/react-in-jsx-scope */
export const consentLabel = [
  {
    text: 'Electronic Consent',
    content: (
      <>
        <b>CONSENT FOR ELECTRONIC SIGNATURES, RECORDS, AND DISCLOSURES (&#34;E-Consent&#34;)</b>
        <br />
        Please read this information carefully and print a copy and/or retain this information electronically for future reference.
        <br />
        <br />
        <u><b>Introduction.</b></u>
        {' '}
        You are using this &apos;Portal&apos; or &apos;Website&apos; to submit a request for consumer financial services from these
        {' '}
        <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lenders</a>
        {' '}
        (each a &#34;Bank&#34;), LendingUSA as operator of the Portal, and their assignees (hereinafter &#34;we,&#34; &#34;us,&#34; &#34;our,&#34; or &#34;Bank&#34;), among other services. To provide these services, we need your consent to using and accepting electronic signatures, records, and disclosures (&#34;E-Consent&#34;). This E-Consent notifies you of your rights when receiving disclosures, notices, and information from the Bank or LendingUSA. By clicking links or buttons assenting to our terms (&#34;Links&#34;), you agree that you received this E-Consent and that you consent to using electronic signatures, records, and disclosures. Additionally, by clicking Links, you consent to conduct transactions by using electronic disclosures, electronic records, and contract documents (&#34;Disclosures&#34;).
        <br />
        <br />
        <u>Option for Paper or Non-Electronic Records.</u>
        {' '}
        You may request any Disclosures in paper copy by logging in and printing a paper copy. You may also mail your written request to LendingUSA, at the following address: 15303 Ventura Blvd. Suite 850, Sherman Oaks, CA 91403. We will provide paper copies at no charge. The Bank or its assignee will retain all Disclosures as applicable law requires.
        <br />
        <br />
        <u><b>Scope of Consent.</b></u>
        {' '}
        This E-Consent applies to all interactions online concerning you and Bank and/or LendingUSA, and includes those interactions engaged in on any computer, electronic device, mobile device, including phones, smart-phones, fax machines, and tablets. Under this E-Consent, Bank and/or LendingUSA will process your information and interact during all online interactions with you electronically. The Disclosures you will receive electronically include federal disclosures, such as the Truth-in-Lending disclosures, Privacy Notices, and notices of our credit decisions, as well as state-specific notices. We will also send you notices electronically related to our interactions and transactions. This E-Consent informs you of your rights when receiving these Disclosures electronically.
        <br />
        <br />
        <u><b>Consenting to Do Business Electronically.</b></u>
        {' '}
        Before you decide to do business electronically with Bank and/or LendingUSA, you should consider whether you have the required hardware and software capabilities described below.
        <br />
        <br />
        <u><b>Hardware and Software Requirements.</b></u>
        {' '}
        To access and retain the Disclosures electronically, you will need to use a computer or device capable of accessing the Internet and an Internet Browser software program that supports at least 256-bit encryption, such as Microsoft® Internet Explorer 11+, Safari 7+ and the evergreen Chrome, Firefox or Edge. To read some documents, you may need a PDF file reader like Adobe® Acrobat Reader, Xpdf® or Foxit®. If these requirements change while you are maintaining an active relationship with Bank, and the change creates a material risk that you may not be able to receive Disclosures electronically, Bank will notify you of these changes. You will need a printer or a long-term storage device, such as your computer&apos;s disk drive, to retain a copy of the Disclosures for future reference. You may send us your written questions regarding the hardware and software requirements to:
        <br />
        <br />
        <b>Email</b>
        <br />
        CustomerCare@LendingUSA.com
        <br />
        <br />
        <b>Call</b>
        <br />
        Our regular business hours are 9:00am to 5:00pm PST
        <br />
        800-994-6177
        <br />
        <br />
        <b>Address for Regular Mail</b>
        <br />
        15303 Ventura Blvd., Suite 850
        <br />
        Sherman Oaks, CA 91403
        <br />
        <br />
        <u><b>Withdrawing Consent.</b></u>
        {' '}
        You are free to withdraw this E-Consent at any time and at no charge. However, if you withdraw this E-Consent before receiving consumer financial services, this will prevent you from obtaining consumer financial services from us. If at any time you wish to withdraw this E-Consent, you can send us your written request by e-mail to CustomerCare@LendingUSA.com with the details of such request. If you decide to withdraw this E-Consent, the withdrawal will not affect the legal effectiveness, validity, and enforceability of prior electronic Disclosures.
        <br />
        <br />
        <u><b>Change to Your Contact Information.</b></u>
        {' '}
        You agree to keep us informed of any change in your electronic address or mailing address. You may update such information by calling us or emailing us to provide the updated information. You may also send us your written update by mail to our address above.
        <br />
        <br />
        <u><b>YOUR ABILITY TO ACCESS DISCLOSURES.</b></u>
        {' '}
        BY CLICKING LINKS, YOU ASSENT TO OUR TERMS. YOU ACKNOWLEDGE AND REPRESENT THAT YOU CAN ACCESS THE DISCLOSURES IN THE DESIGNATED FORMATS DESCRIBED ABOVE. Once you give your consent, you can log into the website to access these documents.
        <br />
        <br />
        <u><b>CONSENT.</b></u>
        {' '}
        BY CLICKING LINKS, YOU ASSENT TO OUR TERMS. YOU ACKNOWLEDGE YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS, DISCLOSURES, AND DOING BUSINESS ELECTRONICALLY. YOU CONSENT TO USING ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM, AND TO DOING BUSINESS WITH US ELECTRONICALLY. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WE WILL PROVIDE TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN YOU NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE THAT YOUR CONSENT TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE CONSUMER FINANCIAL SERVICES FROM US OVER THE INTERNET.
        <br />
        <br />
        Print and retain a hard copy or electronic copy.
        <br />
        <br />
        <b>ELECTRONIC CONSENT AND ACKNOWLEDGMENT.</b>
        {' '}
        BY CLICKING LINKS, YOU CERTIFY THAT:
        <br />
        <ul>
          <li>Your computer hardware and software meet the requirements needed to access and retain the Disclosures electronically.</li>
          <li>You consent to receive the Disclosures electronically.</li>
          <li>You consent to sign your Loan Agreement and related documents electronically.</li>
          <li>You understand and agree that you will be bound to the same terms and conditions if you sign the Disclosures electronically as if you signed paper Disclosures.</li>
        </ul>
      </>),
  },
  {
    text: 'LendingUSA and its lenders’ authorizations, terms, and conditions,',
    content: (
      <div>
        By checking this box you authorize LendingUSA, LLC, (“LendingUSA”), these
        {' '}
        <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lenders</a>
        {' '}
        (each a “Lender”) and their affiliated third parties (“Third Parties”) to forward your loan application (“Application”), including your non-public personal information, to third parties to verify the information contained in your Application, including, but not limited to the following: (1) contacting your employer(s) to verify employment and income; (2) contacting your Merchant Provider (if applicable) to verify any of the following information: (i) the type of procedure(s), service(s) and/or product(s) to be provided; (ii) the date of service; (iii) cost of the goods and/or services to be provided. You acknowledge that you have reviewed and agree to our Privacy Policy which can be found at
        {' '}
        <a href="https://www.lendingusa.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.lendingusa.com/privacy-policy</a>
        {' '}
        and you have reviewed and agree to our Terms of Use which can be found at
        {' '}
        <a href="https://www.lendingusa.com/terms-of-use" target="_blank" rel="noopener noreferrer">https://www.lendingusa.com/terms-of-use</a>
        .
        <br />
        <br />
        You understand that APRs will vary depending upon credit ratings and/or the payment terms that are approved. Credit approvals are valid for a limited time only. You understand that checking your rate and reviewing loan offers on LendingUSA&apos;s Text-to-Apply mobile platform, merchant portal, or any other LendingUSA affiliated online platform results in a soft credit inquiry which does not affect your credit score. However, if you receive a preapproval (“Offer“) from LendingUSA, and you select an Offer provided to you and proceed with acquiring a loan from the Lender, then LendingUSA and/or Lender will request a full credit report from one or more of the credit reporting agencies. This request will appear as a hard inquiry on your credit report and may affect your credit score. You further acknowledge and understand that additional soft credit inquiry reports may be requested and obtained from one or more of the credit reporting agencies during the application process or throughout the term of your loan. These soft pull inquiries will be requested: (1) to confirm that you continue to meet the Lender&apos;s credit criteria; (2) to prevent potential fraudulent activities; (3) for internal modeling and analysis purposes; (4) in servicing or monitoring any product(s) and/or service(s) you may have attained through LendingUSA and/or its Lender; (5) in collecting, enforcing or selling any loan that you may receive from Lender; (6) in providing your credit data to you; (7) in offering you financial products and services; and/or (8) for any other lawful purpose.
        <br />
        <br />
        You authorize your wireless carrier to disclose information about your account, such as subscriber status, payment method and device details, if available, to support identity verification, fraud avoidance and other uses in support of credit transactions. Information may also be shared with other service providers to further support identity verification and fraud prevention.
        <br />
        <br />
        You further authorize and expressly consent to be contacted by Lender, LendingUSA, their agents, representatives, assignees, affiliated third parties, or anyone calling on their behalf for any and all purposes (collectively “Authorized Callers”). This express consent applies to each such telephone number that you provide to any Authorized Caller, either now or in the future, even if the number is on a federal or state do-not-call list. You confirm you are the owner of the phone number(s), or that the owner authorized you to provide this phone number(s), or that you are the regular user of this phone number(s). You are providing express written consent authorizing any Authorized Caller to contact you at this number for any lawful purposes with a live operator, automatic telephone dialing system (auto-dialer) for both phone calls and text/SMS messages, prerecorded and artificial voice message, text/SMS message or other means. Authorization may be revoked as described in our Terms of Use. These telephone calls and text messages may incur message, data and access fees from your telephone provider.
        <br />
        <br />
        Electronic Signature Agreement (&quot;Agreement&quot;). By clicking the &quot;CHECK MY RATE&quot; button, you are consenting to this Agreement and signing your Application electronically. You agree your electronic signature is the legal equivalent of your manual signature on this Application. By clicking &quot;CHECK MY RATE&quot; you consent to be legally bound by LendingUSA&apos;s terms and conditions. You further agree that your use of a keypad, mouse, touch pad or other device to select an item, button, icon or similar act/action online, through LendingUSA&apos;s Text-to-Apply mobile platform, merchant portal, or any other LendingUSA affiliated online platform constitutes your signature (hereafter referred to as &apos;E-Signature&apos;). You also agree that no third-party verification is necessary to validate your E-Signature, and that the lack of such third-party verification will not in any way affect the enforceability of your E-Signature, or of any resulting agreement between you and LendingUSA.
        <br />
        <br />
        By clicking on &quot;CHECK MY RATE&quot; and continuing with this Application, you certify that: 1) you are the Applicant; 2) you are over the age of 18 years; 3) you are residing in the United States and; 4) all information that has been provided by you on this Application is true and accurate.
      </div>),
  },
  {
    text: 'Disclaimer and third-party authorizations, terms, and conditions',
    content: (
      <>
        By checking this box, you: (1) authorize LendingUSA to share your information with Engine by MoneyLion, and for LendingUSA and/or Engine by MoneyLion to share your information with Engine by MoneyLion’s
        {' '}
        <a href="https://fiona.com/learn/partners" target="_blank" rel="noreferrer">network of lenders</a>
        {' '}
        (“Lenders”) so that Lenders can review your eligibility for a loan; (2) are providing written consent under the Fair Credit Reporting Act for Engine by MoneyLion and Lenders to obtain consumer report information from your credit profile; (3) agree to the disclaimer below; and (4) consent to receive communication from Engine by MoneyLion and Lenders, including by text messages or phone calls, under these phone terms below, or by e-mail.
        <br />
        <br />
        <b>Disclaimer</b>
        : By checking this box and clicking ‘CHECK MY RATE’, you acknowledge and agree that you have read this disclaimer, understand it, and agree to be bound by it. Engine by MoneyLion is not a lender.  Rather, Engine by MoneyLion attempts to match you with its Lenders. If Engine by MoneyLion can pre-qualify you with one or more of its Lenders, those Lenders’ loan offers will appear in the following screen. Your use of the products or services provided to you by Engine by MoneyLion and/or its Lenders will be subject to their terms and conditions, terms of use, and privacy policies, and you should review them carefully. If you decide to transact for a product or service from Engine by MoneyLion and/or its Lenders, that transaction and the use of those products and services will be between you, Engine by MoneyLion, and/or its Lenders. LendingUSA and its
        {' '}
        <a href="https://lendingusa.com/lending-partners/" target="_blank" rel="noreferrer">lending partners</a>
        {' '}
        are not parties to your financial transaction with Engine by MoneyLion and/or its Lenders. LendingUSA and its
        {' '}
        <a href="https://lendingusa.com/lending-partners/" target="_blank" rel="noreferrer">lending partners</a>
        {' '}
        do not assume any responsibility or liability for the actions or representations of Engine by MoneyLion and/or its Lenders, its affiliates and/or other third parties. LendingUSA provides you with access to Engine by MoneyLion and/or its Lenders as a convenience.  However, if your loan application is approved by Lenders and subsequently funded, LendingUSA may receive compensation from Engine by MoneyLion and/or its Lenders in connection with this transaction.
        <br />
        <br />
        <b>Phone Terms</b>
        : By checking this box and clicking “CHECK MY RATE”, you are providing express written consent via your electronic signature to receive any type of communication pertaining to your loan application(s), including telemarketing communications, from Engine by MoneyLion and its Lenders, even if that number is on a federal or state do-not-call list. The scope of this consent includes, but is not limited to, receiving live operator calls, calls and texts via an automatic telephone dialing system (auto-dialer), prerecorded and artificial voice messages, text/SMS messages or other means. To opt-out of receiving calls or texts from Engine by MoneyLion and/or its Lenders, you must contact them directly and/or follow their opt-out procedures.
      </>
    ),
  },
];
