import React from 'react';
import PropTypes from 'prop-types';

const SecurityLock = ({ width, className }) => (
  <svg
    width={width}
    height={width * (40 / 28)}
    className={className}
    viewBox="0 0 28 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g id="Borrowers---Apply" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Borrowers---Apply---Step-1---Desktop---V2" transform="translate(-880.000000, -822.000000)" fill="#797A93">
        <g id="Group-11" transform="translate(880.000000, 821.000000)">
          <path d="M22.8868852,36.2231521 C21.3185033,36.4533772 18.1234275,36.6801469 13.6031532,36.6801469 C9.08287889,36.6801469 5.88780303,36.4533772 4.3194212,36.2227202 L4.3194212,20.2071702 L22.8868852,20.2071702 L22.8868852,36.2231521 Z M7.31105232,11.6128179 C7.31105232,8.14259489 10.1337941,5.3194212 13.6031532,5.3194212 C17.0725123,5.3194212 19.895254,8.14259489 19.895254,11.6128179 L19.895254,15.887749 L7.31105232,15.887749 L7.31105232,11.6128179 Z M25.0465958,15.887749 L24.2146752,15.887749 L24.2146752,11.6128179 C24.2146752,5.76086604 19.4542411,1 13.6031532,1 C7.75206522,1 2.99163112,5.76086604 2.99163112,11.6128179 L2.99163112,15.887749 L2.1597106,15.887749 C0.966686464,15.887749 0,16.8548674 0,18.0474596 L0,37.6990983 C0,40.2147292 3.07456401,40.4872847 5.54527293,40.7062794 C7.6820906,40.8950381 10.5437071,41 13.6031532,41 C16.6625992,41 19.5242158,40.89547 21.6610334,40.7062794 C24.1317423,40.4872847 27.2063064,40.2147292 27.2063064,37.6990983 L27.2063064,18.0474596 C27.2063064,16.8548674 26.2396199,15.887749 25.0465958,15.887749 L25.0465958,15.887749 Z M13.6031532,33.3554884 C14.7961773,33.3554884 15.7628638,32.38837 15.7628638,31.1957778 L15.7628638,26.9091842 C15.7628638,25.716592 14.7961773,24.7494736 13.6031532,24.7494736 C12.410129,24.7494736 11.4434426,25.716592 11.4434426,26.9091842 L11.4434426,31.1957778 C11.4434426,32.3888019 12.410129,33.3554884 13.6031532,33.3554884 L13.6031532,33.3554884 Z" id="Fill-1" />
        </g>
      </g>
    </g>
  </svg>
);

SecurityLock.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

SecurityLock.defaultProps = {
  width: 28,
  className: '',
};

export default SecurityLock;
