import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dtcIcon from 'assets/icons/dtc-logo.svg';
import get from 'lodash/get';
import { Button } from 'components/Button';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { parseUrlParams } from 'utils/parseUrlParams';
import { formatCurrency } from 'utils/formatCurrency';
import { nextAction } from 'actions/workflow';
import './style.css';

const OfferSuggest = (props) => {
  const { workflow } = props;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [expandedCards, setExpandedCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorImageStyle, setErrorImageStyle] = useState(false);
  const [thirdPartyModal, setThirdPartyModal] = useState(false);
  const [responseTmp, setReponseTmp] = useState({
    offers: null,
  });
  const [showOffers, setShowOffers] = useState([]);
  const [isOffer, setIsOffer] = useState(false);
  const response = useMemo(() => (responseTmp.offers ? responseTmp : get(workflow, 'data.data')), [responseTmp, workflow]);
  const descriptionRefs = useRef([]);
  const isApplicationDeclined = useMemo(() => get(workflow, 'data.data.applicationStatusName', '') === 'Declined', [workflow]);
  const isAppReceived = useMemo(() => get(workflow, 'data.data.applicationStatusName', '') === 'AppReceived', [workflow]);

  useEffect(() => {
    if (response) {
      if (response.offers && response.offers.length > 0) {
        setShowOffers(response.offers);
        setIsOffer(true);
      } else if (response.specialOffers && response.specialOffers.length > 0) {
        setShowOffers(response.specialOffers);
      } else {
        setShowOffers([]);
      }
    }
  }, [response]);

  const toggleExpand = (index) => {
    if (expandedCards.includes(index)) {
      setExpandedCards(expandedCards.filter((item) => item !== index));
    } else {
      setExpandedCards([...expandedCards, index]);
    }
  };

  const getHeight = (index) => {
    if (expandedCards.includes(index)) {
      return 'auto';
    }
    return '200px';
  };

  const isOriginationPercentageExist = useMemo(() => {
    let result = false;
    (get(response, 'offers') || []).forEach((item) => {
      if (Number(item.originationPercentage) && Number(item.originationPercentage) >= 0) {
        result = true;
      }
    });
    return result;
  }, [response]);
  const isZipTermExist = useMemo(() => {
    let result = false;
    (get(response, 'offers') || []).forEach((item) => {
      if (Number(item.zipTerm)) {
        result = true;
      }
    });
    return result;
  }, [response]);
  const hasEvenFinancialOffers = useMemo(() => {
    const offers = get(response, 'offers', []);
    let result = false;
    offers.forEach((item) => {
      if (item.lenderSource === 'Even Financial') {
        result = true;
      }
    });
    return result;
  }, [response]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = parseUrlParams(window.location.search);

    const { history } = props;
    if (!params.key) {
      history.push(`/applications/${props.match.params.workflowtype}/application`);
    } else if (get(workflow, 'data.data') === undefined) {
      if (params.offername) {
        history.push(`/applications/${props.match.params.workflowtype}/checkin?key=${params.key || ''}&offerName=${params.offername}`);
      } else {
        history.push(`/applications/${props.match.params.workflowtype}/checkin?key=${params.key || ''}`);
      }
    } else {
      setReponseTmp(get(workflow, 'data.data', { offers: null }));
    }
  }, []);

  const selectOffer = (selectedIndexArg, e) => {
    e.preventDefault();
    const res = get(workflow, 'data.data');

    const params = parseUrlParams(window.location.search);
    const url = `/workflows/dtc/${params.key}/next`;
    setIsLoading(true);
    setSelectedIndex(selectedIndexArg);
    props.nextAction({
      data: {
        offerName: isOffer ? res.offers[selectedIndexArg].offerName : res.specialOffers[selectedIndexArg].offerName,
      },
      url,
      success: (res1) => {
        setIsLoading(false);
        setSelectedIndex(null);
        const routeUrl = res1.data && res1.data.url;
        if (isOffer ? (res.offers[selectedIndexArg] && res.offers[selectedIndexArg].lenderSource === 'Even Financial') : res.specialOffers[selectedIndexArg] && res.specialOffers[selectedIndexArg].lenderSource === 'Even Financial') {
          window.open(routeUrl, '_blank');
        } else {
          props.history.push(routeUrl);
        }
      },
      fail: () => {
        setIsLoading(false);
        setSelectedIndex(null);
      },
    });
  };

  const toggle3rdParyModal = (key) => {
    if (thirdPartyModal) {
      setTimeout(() => {
        setSelectedIndex(!thirdPartyModal ? key : -1);
      }, 500);
    } else {
      setSelectedIndex(!thirdPartyModal ? key : -1);
    }
    setThirdPartyModal(!thirdPartyModal);
  };

  useEffect(() => {
    const updateOverflow = () => {
      const newExpandedCards = [];
      showOffers.forEach((_, index) => {
        const descriptionElement = descriptionRefs.current[index];
        if (descriptionElement) {
          newExpandedCards.push({ index, isScroll: descriptionElement.scrollHeight > 200 });
        }
      });
      setExpandedCards(newExpandedCards);
    };

    updateOverflow();
  }, [showOffers]);

  const checkOverflow = (index) => {
    if (expandedCards) {
      const data = expandedCards.filter((item) => item.index === index);
      return data[0]?.isScroll;
    }
  };

  return (
    response ? (
      <div className="page-loanoffer">
        <Container>
          <Row className="justify-content-center">
            {/* {!isOffer ? (
              <Col xs={12}>
                <p className="mb-3">Unfortunately, there are currently no loan programs available that match your criteria. However, based on your information, the following special offers may be able to help you today.</p>
              </Col>
            ) : null} */}
            <Col lg={10} xl={8}>
              <Row>
                <Col className="md-4 mt-1 mt-md-3">
                  <p className="mb-0">
                    <b>Application ID:</b>
                    {' '}
                    {get(workflow, 'attributes.entityId')}
                  </p>
                </Col>
              </Row>
              {isApplicationDeclined && !isOffer && showOffers.length > 0 ? (
                <Row>
                  <Col className="text-center mt-2 mt-md-4">
                    <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
                    <h2 className="mb-2">Unfortunately, your application was not approved at this time.</h2>
                    <p className="mb-4">You will receive an adverse action notice within 30 days that will provide you with the specific reason(s) as to why your application was not approved.</p>
                  </Col>
                </Row>
              ) : null}
              {isAppReceived && !isOffer && showOffers.length > 0 ? (
                <Row>
                  <Col className="text-center mt-2 mt-md-4">
                    <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
                    <h2 className="mb-2">Unfortunately, there are currently no loan programs available that match your criteria.</h2>
                    <p className="mb-4">However, based on your information, the following special offers may be able to help you today.</p>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col className="md-4">
                  <h1>
                    Select a
                    {' '}
                    {isOffer ? 'Loan Offer' : 'Special Offer'}
                  </h1>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <h3 className="border-bottom pb-2 mb-3">
                    Please select one of the
                    {' '}
                    {isOffer ? 'loan' : ''}
                    {' '}
                    offers below
                  </h3>
                </Col>
                {
                  showOffers.map((item, key) => (
                    <Col xs={12} md={6} key={key}>
                      <Card style={{ backgroundColor: '#d7e7f9' }}>
                        <CardHeader>
                          {isOffer
                            ? (
                              <h5 className="mb-0 text-primary font-weight-700">
                                {item.term}
                                {' '}
                                Months
                                {item.lenderSource === 'Even Financial' ? <span style={{ fontWeight: 400, color: '#3f556e' }}> ‡</span> : ''}
                              </h5>
                            )
                            : (
                              <h5 className="mb-0 text-primary font-weight-700">
                                Special Offer #
                                {key + 1}
                              </h5>
                            )}
                        </CardHeader>
                        <Container fluid className="p-0">
                          <Row noGutters>
                            {
                              item.lenderImageUrl && (
                                <Col xs={12} className="bg-white pt-3 pl-3 pr-3 text-center" style={{ zIndex: 1, position: 'relative' }}>
                                  <div
                                    className="d-flex align-items-start justify-content-center"
                                    style={{ gap: 5 }}
                                  >
                                    {/* {setErrorImageStyle(false)} */}
                                    <img
                                      style={{ height: 40, filter: errorImageStyle ? 'grayscale(1)' : 'unset', opacity: errorImageStyle ? '0.5' : 'unset' }}
                                      src={item.lenderImageUrl}
                                      alt="fsl logo"
                                      onError={(e) => {
                                        e.target.src = dtcIcon;
                                        e.onerror = null;
                                        setErrorImageStyle(true);
                                      }}
                                    />
                                    {item.lenderSource === 'Even Financial' && (
                                      <img
                                        src="/icons/info.svg"
                                        alt="info icon"
                                        width={18}
                                        style={{ cursor: 'pointer' }}
                                        onClick={toggle3rdParyModal.bind(this, key)}
                                      />
                                    )}
                                  </div>
                                </Col>
                              )
                            }
                            {isOffer
                              ? (
                                <Col xs={12} className="bg-white pt-3 pl-3 pr-3 text-center" style={{ zIndex: 1, position: 'relative' }}>
                                  {
                                !!Number(item.zipTerm) && (
                                  <>
                                    <h2 className="mb-0 text-success text-center" style={{ fontWeight: 500 }}>
                                      {
                                        Number(item.originationPercentage) ? 'NO INTEREST' : '0% APR'
                                      }
                                    </h2>
                                    <h5 className="mb-2">
                                      if paid within
                                      {' '}
                                      {item.zipTerm}
                                      {' '}
                                      months*
                                    </h5>
                                  </>
                                )
                              }
                                  <h2 className="mb-0 text-primary font-weight-900 text-center">
                                    $
                                    {formatCurrency(item.paymentAmount, 2) || '-'}
                                  </h2>
                                  <h5><b>Est. Monthly Payment</b></h5>
                                </Col>
                              ) : null}
                            <Col xs={12} className="bg-white">
                              <Container className="p-3" fluid>
                                {isOffer
                                  ? (
                                    <>
                                      <Row className="mb-1" noGutters>
                                        <Col xs={8}>
                                          <h5 className="mb-0 text-secondary">Amount Financed</h5>
                                        </Col>
                                        <Col xs={4}>
                                          <strong>
                                            $
                                            {formatCurrency(item.approvalAmount, 2) || '-'}
                                          </strong>
                                        </Col>
                                      </Row>
                                      <Row className="mb-1" noGutters>
                                        <Col xs={8}><h5 className="mb-0 text-secondary">Loan Term</h5></Col>
                                        <Col xs={4}>
                                          <strong>
                                            {item.term}
                                            {' '}
                                            Months
                                          </strong>
                                        </Col>
                                      </Row>
                                      {
                                  item.lenderSource !== 'Even Financial' && (
                                    <Row className="mb-1" noGutters>
                                      <Col xs={8}><h5 className="mb-0 text-secondary">Interest Rate</h5></Col>
                                      <Col xs={4}>
                                        <strong>
                                          {formatCurrency(item.rate, 2) || '-'}
                                          %
                                        </strong>
                                      </Col>
                                    </Row>
                                  )
                                }
                                      <Row className="mb-1" noGutters>
                                        <Col xs={8}><h5 className="mb-0 text-secondary">Est. APR</h5></Col>
                                        <Col xs={4}>
                                          <strong>
                                            {formatCurrency(item.apr, 4) || '-'}
                                            %
                                          </strong>
                                        </Col>
                                      </Row>
                                      {
                                  item.lenderSource !== 'Even Financial' && !!Number(item.originationPercentage) && (
                                    <Row className="mb-1" noGutters>
                                      <Col xs={8}>
                                        <h5 className="mb-0 text-secondary">Origination Fee**</h5>
                                        <small className="text-secondary">Included in monthly payment</small>
                                      </Col>
                                      <Col xs={4}>
                                        <strong>
                                          {Number(item.originationPercentage) ? formatCurrency(Number(item.originationPercentage) * 100, 2) : '0'}
                                          %
                                        </strong>
                                      </Col>
                                    </Row>
                                  )
                                }
                                    </>
                                  ) : (
                                    <>
                                      <div key={key} ref={(el) => { descriptionRefs.current[key] = el; }} style={{ height: getHeight(key), overflow: 'hidden', minHeight: '200px' }}>
                                        <div dangerouslySetInnerHTML={{ __html: item.lenderDescription }} />
                                      </div>
                                      <div style={{ height: '20px' }}>
                                        {checkOverflow(key) && (
                                        <button type="button" onClick={() => toggleExpand(key)} className="expandButton">
                                          {expandedCards.includes(key) ? 'Show Less' : 'Show More...'}
                                        </button>
                                        )}
                                      </div>
                                    </>
                                  )}
                                <Row className="mb-1" noGutters>
                                  <Button
                                    color="primary"
                                    className="w-100 mt-2"
                                    onClick={selectOffer.bind(null, key)}
                                    isLoading={isLoading && key === selectedIndex}
                                  >
                                    Select
                                  </Button>
                                </Row>
                              </Container>
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  ))
                }
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10} xl={8} className="text-center">
              {
                isZipTermExist
                  && (
                  <p style={{ fontSize: '.8rem' }}>
                    * Your loan may have a No Interest on Principal Option Promotion included. This promotion can save you money if you pay off the principal amount of the loan in full within the Promotional Period (&#34;Promotional Period&#34;). During the Promotional Period you will be responsible for making all of your monthly payments and your loan will accrue interest on a monthly basis. If you pay off your loan within the Promotional Period, the monthly payments that you have made during this period, which includes accrued interest, will be deducted from the principal amount of the loan. Length of Promotional Periods vary, please review your loan agreement for full details.
                  </p>
                  )
              }
              {
                isOriginationPercentageExist
                  && (
                  <p style={{ fontSize: '.8rem' }}>
                    ** The Origination Fee may be up to 8% of the Amount Financed, is non-refundable, and is considered earned upon the funding of your loan. You can calculate the dollar amount of the Origination Fee for each offer listed above by multiplying the Amount Financed figure for that offer by .08 if the Origination Fee is 8%, by .05 if it is 5%, etc. For example, if the Origination Fee is 8% and if the Amount Financed is $1,000 the corresponding Origination Fee would be $80 ($1000 x .08 = $80). The total Principal Amount of your loan is the sum of the Amount Financed and the Origination Fee (i.e. if the Origination Fee is 8% and if the Amount Financed is $1,000 the Principal Amount of the loan would be $1080). Please review your Loan Agreement for additional terms and conditions.
                  </p>
                  )
              }
              {
                hasEvenFinancialOffers && (
                  <p style={{ fontSize: '.8rem' }}>
                    ‡ This is not a loan through LendingUSA made by its
                    {' '}
                    <a href="https://lendingusa.com/lending-partners/" target="_blank" rel="noopener noreferrer">lending partners</a>
                    . The offers that appear are from companies from which LendingUSA and Engine by MoneyLion and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed above. However, this compensation also facilitates the provision by LendingUSA and Engine by MoneyLion of certain services to you at no charge. All rates, fees, and terms are presented without guarantee and are subject to change pursuant to each provider’s discretion and may not be available in all states or for all types of loans. There is no guarantee you will be approved or qualify for the advertised rates, fees, or terms presented. This website does not include all lending companies or all available lending offers that may be available to you.
                  </p>
                )
              }
            </Col>
          </Row>
          <Modal isOpen={thirdPartyModal} toggle={toggle3rdParyModal} size="lg">
            <ModalHeader toggle={toggle3rdParyModal}>
              {isOffer ? get(response, `offers.${selectedIndex}.offerName`) : get(response, `specialOffers.${selectedIndex}.lender`)}
            </ModalHeader>
            <ModalBody style={{ maxHeight: 600, overflow: 'auto' }}>
              <div
                style={{ fontSize: '0.875rem', textAlign: !isOffer && 'center' }}
                dangerouslySetInnerHTML={{
                  __html: isOffer ? get(response, `offers.${selectedIndex}.lenderDescription`) : get(response, `specialOffers.${selectedIndex}.offerName`),
                }}
              />
              <div
                className="mb-0"
                style={{ fontSize: '0.85rem', textAlign: 'center' }}
                dangerouslySetInnerHTML={{
                  __html: isOffer ? (get(response, `offers.${selectedIndex}.lenderDisclaimer`)
                    && get(response, `offers.${selectedIndex}.lenderDisclaimer`)
                      .replaceAll('\n', "<br style='content: \"\"; height: 0.5rem; display: block;'/>")) : get(response, `specialOffers.${selectedIndex}.lenderDisclaimer`)
                      && get(response, `specialOffers.${selectedIndex}.lenderDisclaimer`)
                        .replaceAll('\n', "<br style='content: \"\"; height: 0.5rem; display: block;'/>"),
                }}
              />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    ) : (
      <div />
    )
  );
};

OfferSuggest.propTypes = {
  history: PropTypes.object.isRequired,
  nextAction: PropTypes.func.isRequired,
  workflow: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      workflow: state.workflow,
    }),
    {
      nextAction,
    }
  )
)(OfferSuggest);
