import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import { checkPreviousAction } from 'actions/workflow';
import { parseUrlParams } from 'utils/parseUrlParams';
import './style.scss';

const OfferConfirmation = ({ workflow, history, match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const params = parseUrlParams(window.location.search);

    if (get(workflow, 'data.data') === undefined) {
      history.push(`/applications/${match.params.workflowtype}/checkin?key=${params.key || ''}`);
    }

    if (!params.key) {
      history.push('/');
    }
  }, []);

  return (
    <div className="offer-confirmation page-preapproved narrow">
      <Container>
        <Row>
          <Col className="text-center mb-2 mt-2 mt-md-4">
            <p className="mb-0 text-left">
              <b>Application ID:</b>
              {' '}
              {get(workflow, 'attributes.entityId')}
            </p>
            <img src="/icons/mobile-phone-icon.svg" alt="Pro-Approved" className="mb-3" />
            <h1 className="title">
              Please Check Your Phone.
            </h1>
            <h1 className="desc mb-2 font-weight-normal">A text message has been sent to proceed with your loan.</h1>
            <p className="mb-4">Your loan approval is subject to identity and income verification.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

OfferConfirmation.propTypes = {
  history: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

OfferConfirmation.defaultProps = {
};

export default compose(
  withRouter,
  connect(
    (state) => ({
      workflow: state.workflow,
    }),
    {
      checkPreviousAction,
    }
  )
)(OfferConfirmation);
