import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { unmask } from 'utils/masks';
import {
  Row, Col,
} from 'reactstrap';

import Slider from 'components/Slider';
import { MIN_LOAN_AMOUNT, MAX_LOAN_AMOUNT } from 'utils/loanAmounts';

const currencyMask = createNumberMask({
  prefix: '$ ',
  allowDecimal: true,
  integerLimit: 5,
});

let labels = {
  1000: '$1k',
  4000: '$4k',
  7000: '$7k',
  10000: '$10k',
};

if (MAX_LOAN_AMOUNT === 38000) {
  labels = {
    9000: '$9k',
    18000: '$18k',
    27000: '$27k',
    36000: '$36k',
  };
} else if (MAX_LOAN_AMOUNT === 47500) {
  labels = {
    9000: '$9k',
    18000: '$18k',
    27000: '$27k',
    36000: '$36k',
    45000: '$45k',
  };
}

const loanAmountRequested = ({ amount, onChange, hasError, onBlur, errorMessage }) => (
// LoanAmountRequested
  <Row className="mt-md-3 mb-4">
    <Col xs={12}>
      <h3 className="border-bottom pb-2 mb-1 mb-md-3">Loan Amount Requested</h3>
    </Col>
    <Col md={8} className="mb-1 mb-md-0">
      <Slider
        value={parseInt(unmask(`${amount}`), 10) || MIN_LOAN_AMOUNT}
        orientation="horizontal"
        handleChange={onChange}
        min={MIN_LOAN_AMOUNT}
        max={MAX_LOAN_AMOUNT}
        step={100}
        labels={labels}
        format={(value) => `$ ${value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
      />
    </Col>
    <Col md={4}>
      <label className={cn('has-value', 'label', hasError && 'required')}>
        {
          hasError && (
            <span>
              <em>
                {(errorMessage === 'The input field is required') ? 'Required' : 'Error'}
              </em>
            </span>
          )
        }
        <MaskedInput
          name="requestedAmount"
          mask={currencyMask}
          className="form-control ui-input requestedAmount"
          type="text"
          required="required"
          placeholder="0.00"
          value={amount}
          onChange={(e) => {
            if (unmask(e.target.value) <= MAX_LOAN_AMOUNT) {
              onChange(unmask(e.target.value));
            } else {
              onChange(unmask(amount));
            }
          }}
          onBlur={onBlur}
        />
      </label>
    </Col>
    {
      hasError && (
        <Col className="small-12">
          <div className="error">{errorMessage !== 'The input field is required' && errorMessage}</div>
        </Col>
      )
    }
  </Row>
  // LoanAmountRequested
);

loanAmountRequested.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

loanAmountRequested.defaultProps = {
  amount: 2220,
  hasError: false,
  errorMessage: '',
};

export default loanAmountRequested;
