import { removeSpace } from 'utils/masks';

const fundingAccountSchema = {
  bankName: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  accountHolderName: [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }

      if (input.length > 40) {
        return 'The input field is too long';
      }

      if (input.trim().split(' ').length < 2) {
        return 'Please enter your full name';
      }

      if (!/^[a-zA-Z\s-']+$/.test(input)) {
        return 'Please enter a valid name, with only letters, - and \'.';
      }

      return true;
    },
  }],
  accountType: [{
    rule: 'required',
    error: 'The input field is required',
  }],
  routingNumber: [
    {
      rule: 'required',
      isValid: (input, err, opt, formData) => {
        if (!input) {
          return 'The input field is required';
        }

        if (`${input}`.replace('_', '').length < 9 || !formData?.isValidRoutingNumber) {
          return 'Routing Number is not valid';
        }

        return /^(\d)\1+$/.test(input) ? 'Routing Number is not valid' : true;
      },
    },
  ],
  accountNumber: [
    {
      rule: 'required',
      isValid: (input) => {
        if (!input) {
          return 'The input field is required';
        }

        return /^(\d)\1+$/.test(input) ? 'Account Number is not valid' : true;
      },
    },
  ],
};

export default fundingAccountSchema;
