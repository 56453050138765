import { call, takeEvery } from 'redux-saga/effects';
import { LookupConstants } from 'constants/lookup';
import { request } from 'components/Request';

function* fetchRentOrOwn(action) {
  yield call(request({
    type: LookupConstants.FETCH_RENT_OR_OWN,
    method: 'GET',
    apiUrl: action.payload.apiUrl,
    url: action.payload.url,
  }), action);
}

function* fetchEmploymentYears(action) {
  yield call(request({
    type: LookupConstants.FETCH_EMPLOYMENT_YEARS,
    method: 'GET',
    apiUrl: action.payload.apiUrl,
    url: action.payload.url,
  }), action);
}

function* fetchEmploymentStatus(action) {
  yield call(request({
    type: LookupConstants.FETCH_EMPLOYMENT_STATUS,
    method: 'GET',
    apiUrl: action.payload.apiUrl,
    url: action.payload.url,
  }), action);
}

function* getTestValuesData(action) {
  yield call(request({
    type: LookupConstants.GET_TEST_VALUES_DATA,
    method: 'GET',
    apiUrl: action.payload.apiUrl,
    url: action.payload.url,
  }), action);
}

const LookupSaga = function* () {
  yield takeEvery(LookupConstants.FETCH_RENT_OR_OWN, fetchRentOrOwn);
  yield takeEvery(LookupConstants.FETCH_EMPLOYMENT_YEARS, fetchEmploymentYears);
  yield takeEvery(LookupConstants.FETCH_EMPLOYMENT_STATUS, fetchEmploymentStatus);
  yield takeEvery(LookupConstants.GET_TEST_VALUES_DATA, getTestValuesData);
};

export default LookupSaga;
