import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { format } from 'date-fns';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { conformToMask } from 'react-text-mask';
import ReactLoading from 'react-loading';
import { Button } from 'components/Button';
import Validator from 'components/Validator/Validator';
import LifeSavers from 'components/Sidebar/LifeSavers';
import {
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Popover,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';
import { formatCurrency } from 'utils/formatCurrency';
import { toTitleCase } from 'utils/toTitleCase';
import { routingNumberMask, accountMask, numberUnmask } from 'utils/masks';

import imgError from 'assets/images/error.svg';
import VerifiedIcon from 'assets/images/correct.png';
import WarningIcon from 'assets/images/warning.png';

import {
  nextAction,
  updateOffer,
  checkPreviousAction,
  validateRoutingNumberAction,
} from 'actions/workflow';

import schema from './schema';
import fundingAccountSchema from './fundingAccountSchema';
import './style.scss';

const FundingAccount = (props) => {
  const { workflow, validator: { values, errors } } = props;
  const [modal, setModal] = useState(false);
  const [iovationBlackBox, setIovationBlackBox] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTCPAModalVisible, setIsTCPAModalVisible] = useState(false);
  const [isDisclosureModalVisible, setIsDisclosureModalVisible] = useState(false);
  const [isElectronicModalVisible, setIsElectronicModalVisible] = useState(false);
  const [isPatriotActPopoverOpen, setIsPatriotActPopoverOpen] = useState(false);
  const [routingValidateStatus, setRoutingValidateStatus] = useState({
    loading: false,
    verified: false,
    checkedFirstTime: false,
    data: null,
  });

  const response = useMemo(() => get(workflow, 'data.data'), [workflow]);
  const selectedIndex = useMemo(() => get(response, 'offers', []).findIndex((offer) => offer.selected), [response]);
  const isOriginationPercentageExist = useMemo(() => {
    (get(response, 'offers') || []).forEach((item) => {
      if (Number(item.originationPercentage)) return true;
    });
    return false;
  }, [response]);
  const isZipTermExist = useMemo(() => {
    (get(response, 'offers') || []).forEach((item) => {
      if (Number(item.zipTerm)) return true;
    });
    return false;
  }, [response]);

  const getCurrentDate = () => format(new Date(), 'MM/dd/yyyy');

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = parseUrlParams(window.location.search);

    const { history, ipAddress } = props;

    if (!params.key) {
      history.push('/applications/dtc1/application');
    } else if (get(workflow, 'data.data') === undefined) {
      history.push(`/applications/dtc1/checkin${window.location.search}`);
    } else {
      // iovation
      let iovationBlackBoxTemp;
      window.io_global_object_name = 'IGLOO';
      window.IGLOO = {
        enable_flash: false,
        bb_callback: (bb, complete) => { // eslint-disable-line
          if (!iovationBlackBox) {
            iovationBlackBoxTemp = bb;
            setIovationBlackBox(bb);
          } else {
            iovationBlackBoxTemp = iovationBlackBox;
          }
        },
        loader: {
          subkey: '',
          version: 'general5',
        },
      };
      const script = document.createElement('script');
      script.async = true;
      script.src = '/assets/js/iovation_loader_only.js';
      document.head.appendChild(script);
      // End iovation

      if (ipAddress !== 'Not able to detect IP Address!') {
        const payload = {
          ServiceDate: getCurrentDate(),
          iovationBlackBox: iovationBlackBoxTemp,
          sourceIP: ipAddress,
        };

        let offerName = null;

        if (params.offername) {
          offerName = params.offername;
        } else if (get(workflow, 'data.data.offerName')) {
          offerName = get(workflow, 'data.data.offerName');
        }

        if (offerName) {
          payload.offerName = offerName;

          const url = `workflows/dtc/${params.key}/checkin`;
          props.checkPreviousAction({
            data: payload,
            url,
            success: (res) => {
              const routeUrl = res.data.url;
              props.history.push(routeUrl);
              setIsReady(true);
            },
            fail: (error) => {
              console.log('error ', error);
            },
          });
        } else if (workflow) {
          setIsReady(true);
        }
      }
    }
  }, [props.ipAddress]);

  const handleTryAgain = (e) => {
    e.preventDefault();
    setErrorMessage('');
  };

  const handleFail = (err) => {
    setErrorMessage(get(err, 'data.failure.errors.0.message') || 'An unexpected error occurred, please try again');
    setIsSaving(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const params = parseUrlParams(window.location.search);
    const { ipAddress, validator: { validate, setValues } } = props;

    if (validate(fundingAccountSchema).isValid) {
      const url = `workflows/dtc/${params.key}/checkin`;
      setIsSaving(true);
      props.checkPreviousAction({
        data: {
          ...values,
          ServiceDate: getCurrentDate(),
          iovationBlackBox,
          sourceIP: ipAddress,
        },
        url,
        success: (res) => {
          if (get(res, 'data.data.requestConsent') && !get(res, 'data.data.phoneTypeMismatch')) {
            setValues({
              ...values,
              phoneNumber: conformToMask(get(res, 'data.data.phoneNumber'), ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]).conformedValue,
            });
          }
          setIsSaving(false);
          setModal(true);
        },
        fail: handleFail,
      });
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleBlur = (event) => {
    event.preventDefault();

    const { validator: { onChangeHandler } } = props;
    onChangeHandler(event.target.name, toTitleCase(event.target.value));
  };

  const handleFinalizeSale = (e) => {
    e.preventDefault();
    const { validator: { validate, setErrors } } = props;
    const phoneTypeMismatch = get(response, 'phoneTypeMismatch');
    const requestConsent = get(response, 'requestConsent');
    const params = parseUrlParams(window.location.search);

    if (params.key && ((phoneTypeMismatch && validate(schema).isValid) || !phoneTypeMismatch)) {
      setIsLoading(true);

      const payload = {
        phoneNumber: (phoneTypeMismatch || requestConsent) ? numberUnmask(values.phoneNumber) : null,
      };

      props.nextAction({
        data: payload,
        url: `/workflows/dtc/${params.key}/next`,
        success: (res) => {
          setIsLoading(false);
          const routeUrl = res.data && res.data.url;
          props.history.push(routeUrl);
        },
        fail: (error) => {
          setIsLoading(false);
          if (get(error, 'status') === 400 && get(error, 'data.failure.errors.0.subject') === 'phoneNumber') {
            setErrors({
              phoneNumber: get(error, 'data.failure.errors.0.message'),
            });
          }
        },
      });
    }
  };

  const validateRoutingNo = (routingNumber) => {
    const newValue = routingNumber.replace(/[^\d]/g, '');
    if (newValue.length === 9) {
      return true;
    }
    return false;
  };

  const validateRoutingNumber = (routingNo) => {
    const { validator: { setValues, setErrors, deleteErrors } } = props;
    let routingNumberUnMask;
    if (routingNo && routingNo.length === 9) {
      routingNumberUnMask = routingNo.replace(/[^\d]/g, '');
    }
    if (!routingNumberUnMask && routingNumberUnMask?.length !== 9) return;
    setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, loading: true, data: null });
    setValues({ ...values, bankName: '', isValidRoutingNumber: false });
    if (routingNo) {
      props.validateRoutingNumberAction({
        routingNumber: routingNo,
        success: (res) => {
          const validateRespose = res && res.length > 0 ? res[0] : null;
          const validateStatus = validateRespose && validateRespose.status;
          const validateData = validateRespose && validateRespose.data;

          if (validateStatus && validateStatus === 'success') {
            setValues({ ...values, bankName: validateData.name, routingNumber: routingNo, isValidRoutingNumber: true });
            deleteErrors(['bankName', 'routingNumber']);
            setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, verified: true, loading: false, data: validateData });
          }
          if (validateStatus && validateStatus === 'fail') {
            setErrors({
              ...errors,
              routingNumber: validateData.message || 'An error occurred',
            });
            setValues({ ...values, bankName: '', routingNumber: routingNo, isValidRoutingNumber: false });
            setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, verified: false, loading: false, data: null });
          }
        },
        fail: () => {
          setRoutingValidateStatus({ ...routingValidateStatus, checkedFirstTime: true, verified: false, loading: false, data: null });
        },
      });
    }
  };

  const handleInputChange = (event) => {
    const { validator: { onChangeHandler } } = props;

    if (
      event.target.name === 'routingNumber'
      && validateRoutingNo(event.target.value)
      && !routingValidateStatus.loading
    ) {
      validateRoutingNumber(event.target.value);
    }

    onChangeHandler(event.target.name, event.target.value);
  };

  const toggleTCPAModal = () => {
    setIsTCPAModalVisible(!isTCPAModalVisible);
  };

  const toggleDisclousureModal = () => {
    setIsDisclosureModalVisible(!isDisclosureModalVisible);
  };

  const toggleElectronicModal = () => {
    setIsElectronicModalVisible(!isElectronicModalVisible);
  };

  return (
    response && isReady
      ? (
        <div className="page-loanoffer">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} xl={6}>
                <Row>
                  <Col className="md-4 mt-1 mt-md-3">
                    <p className="mb-0">
                      <b>Application ID:</b>
                      {' '}
                      {get(workflow, 'attributes.entityId')}
                    </p>
                    <h1>
                      Please Enter The Account
                      <br />
                      You Want Funded
                    </h1>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col xs={12}>
                    <p className="border-bottom pb-2 mb-3" style={{ fontWeight: 500, color: '#999' }}>Please enter the account you want your loan proceeds deposited into.</p>
                  </Col>

                  <Col xs={12}>
                    <Input
                      label="Name on Account"
                      name="accountHolderName"
                      value={values.accountHolderName}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      isRequired
                      hasError={!!errors.accountHolderName}
                      errorMessage={errors.accountHolderName}
                      maxLength={40}
                    />
                  </Col>

                  <Col xs={12}>
                    <Select
                      label="Account Type"
                      name="accountType"
                      data={[
                        { value: 'Checking', title: 'Checking' },
                        { value: 'Savings', title: 'Savings' },
                      ]}
                      value={values.accountType ? `${values.accountType}` : ''}
                      onChange={handleInputChange}
                      hasError={errors.accountType}
                      errorMessage={errors.accountType}
                      isRequired
                    />
                  </Col>

                  <Col xs={12}>
                    <Input
                      label="9-Digit Routing Number"
                      name="routingNumber"
                      value={values.routingNumber}
                      onChange={handleInputChange}
                      isMasked={routingNumberMask}
                      isRequired
                      hasError={!!errors.routingNumber}
                      errorMessage={errors.routingNumber}
                    />
                    {values.bankName ? (
                      <div className="bankName">
                        <span>{values.bankName}</span>
                      </div>
                    ) : null}
                    {routingValidateStatus.loading
                      ? <span className="input-loader" />
                      : routingValidateStatus.checkedFirstTime
                        ? (
                          <span className="input-status">
                            <img
                              src={`${routingValidateStatus.verified ? VerifiedIcon : WarningIcon}`}
                              alt="input-status"
                            />
                          </span>
                        )
                        : null}
                  </Col>

                  <Col xs={12}>
                    <Input
                      label="Account Number"
                      name="accountNumber"
                      value={values.accountNumber}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      isMasked={accountMask}
                      isRequired
                      hasError={!!errors.accountNumber}
                      errorMessage={errors.accountNumber}
                    />
                  </Col>
                  <Col xs={6}>
                    <div className="mt-1">
                      <Button
                        className="large w-100 submit-button"
                        onClick={handleSave}
                        isLoading={isSaving}
                        color="primary"
                        size="lg"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <LifeSavers />
            </Row>
            <Row className="justify-content-center">
              <Col lg={10} xl={8} className="text-center">
                <p style={{ fontSize: '.8rem' }}>
                  Terms are subject to change based on the first payment date that you will select.
                </p>
                {
                isZipTermExist
                  && (
                  <p style={{ fontSize: '.8rem' }}>
                    * Your loan may have a No Interest on Principal Option Promotion included. This promotion can save you money if you pay off the principal amount of the loan in full within the Promotional Period (&#34;Promotional Period&#34;). During the Promotional Period you will be responsible for making all of your monthly payments and your loan will accrue interest on a monthly basis. If you pay off your loan within the Promotional Period, the monthly payments that you have made during this period, which includes accrued interest, will be deducted from the principal amount of the loan. Length of Promotional Periods vary, please review your loan agreement for full details.
                  </p>
                  )
              }
                {
                isOriginationPercentageExist
                  && (
                  <p style={{ fontSize: '.8rem' }}>
                    ** The Origination Fee may be up to 8% of the Amount Financed, is non-refundable, and is considered earned upon the funding of your loan. You can calculate the dollar amount of the Origination Fee for each offer listed above by multiplying the Amount Financed figure for that offer by .08 if the Origination Fee is 8%, by .05 if it is 5%, etc. For example, if the Origination Fee is 8% and if the Amount Financed is $1,000 the corresponding Origination Fee would be $80 ($1000 x .08 = $80). The total Principal Amount of your loan is the sum of the Amount Financed and the Origination Fee (i.e. if the Origination Fee is 8% and if the Amount Financed is $1,000 the Principal Amount of the loan would be $1080). Please review your Loan Agreement for additional terms and conditions.
                  </p>
                  )
              }
              </Col>
            </Row>
            <Modal className="offer-select-modal" isOpen={modal} toggle={handleModal} size="md">
              <ModalHeader toggle={handleModal}>You selected the following offer:</ModalHeader>
              <ModalBody>
                <Row className="mb-1">
                  {
                  !!Number(get(response, `offers.${selectedIndex}.zipTerm`)) && (
                    <Col sm={6} className="border-right">
                      <FormGroup className="text-right pb-1 pt-1 mb-0 text-center-override">
                        <div className="d-inline-block text-center">
                          <h2 className="mb-0 text-success text-center" style={{ fontWeight: 500 }}>
                            {
                              Number(get(response, `offers.${selectedIndex}.originationPercentage`)) ? 'NO INTEREST' : '0% APR'
                            }
                          </h2>
                          <h5 className="text-center">
                            if paid within
                            {' '}
                            {get(response, `offers.${selectedIndex}.zipTerm`)}
                            {' '}
                            months*
                          </h5>
                        </div>
                      </FormGroup>
                    </Col>
                  )
                }
                  <Col sm={12}>
                    <Row>
                      <Col sm={6} style={{ fontSize: '16px' }}>
                        <strong>
                          Est. Monthly
                          <br />
                          Payment
                        </strong>
                      </Col>
                      <Col sm={6} style={{ fontSize: '30px' }} className="text-right text-primary">
                        <b>
                          $
                          {formatCurrency(get(response, `offers.${selectedIndex}.paymentAmount`), 2) || '-'}
                        </b>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mt-1">
                    <Row>
                      <Col sm={6}>Amount Financed</Col>
                      <Col sm={6} className="text-right">
                        <strong>
                          $
                          {formatCurrency(get(response, `offers.${selectedIndex}.approvalAmount`), 2) || '-'}
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mt-1">
                    <Row>
                      <Col sm={6}>Loan Term</Col>
                      <Col sm={6} className="text-right">
                        <strong>
                          {get(response, `offers.${selectedIndex}.term`)}
                          {' '}
                          Months
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                  {
                  !!Number(get(response, `offers.${selectedIndex}.originationPercentage`)) && (
                    <Col sm={12} className="mt-1">
                      <Row>
                        <Col sm={6}>Origination Fee</Col>
                        <Col sm={6} className="text-right">
                          <strong>
                            {Number(get(response, `offers.${selectedIndex}.originationPercentage`))
                              ? formatCurrency(Number(get(response, `offers.${selectedIndex}.originationPercentage`)) * 100, 2)
                              : '0'}
                            %
                          </strong>
                        </Col>
                      </Row>
                    </Col>
                  )
                }
                  <Col sm={12} className="mt-1">
                    <Row>
                      <Col sm={6}>Interest Rate</Col>
                      <Col sm={6} className="text-right">
                        <strong>
                          {formatCurrency(get(response, `offers.${selectedIndex}.rate`), 2) || '-'}
                          %
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mt-1">
                    <Row>
                      <Col sm={6}>Est. APR</Col>
                      <Col sm={6} className="text-right">
                        <strong>
                          {formatCurrency(get(response, `offers.${selectedIndex}.apr`), 4) || '-'}
                          %
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                  {
                  get(response, 'phoneTypeMismatch') && (
                    <>
                      <Col sm={{ size: 10, offset: 1 }} className="text-center mt-2">
                        {`Unfortunately, XXX-XXX-${get(response, 'phoneLast4Digits')} is not a mobile number. Please provide a valid mobile number to continue.`}
                      </Col>
                      <Col sm={{ size: 8, offset: 2 }} className="text-center mt-2">
                        <Input
                          label="Mobile Phone"
                          name="phoneNumber"
                          placeHolder="(___) ___-____"
                          isMasked={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          value={values.phoneNumber}
                          onChange={handleInputChange}
                          isRequired
                          hasError={!!errors.phoneNumber}
                          errorMessage={errors.phoneNumber}
                        />
                      </Col>
                    </>
                  )
}
                  {get(response, 'requestConsent') && get(response, 'phoneNumber') && !get(response, 'phoneTypeMismatch') && (
                  <>
                    <Col sm={{ size: 12 }} className="text-left text-13 mt-2">
                      Please confirm your mobile number.
                    </Col>
                    <Col sm={{ size: 12 }} className="text-left mt-2">
                      <Input
                        label="Mobile Phone"
                        name="phoneNumber"
                        placeHolder="(___) ___-____"
                        isMasked={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        value={values.phoneNumber}
                        onChange={handleInputChange}
                        isRequired
                        hasError={!!errors.phoneNumber}
                        errorMessage={errors.phoneNumber}
                      />
                    </Col>
                  </>
                  )}
                  {get(response, 'requestConsent')
                  && (
                  <Col sm={{ size: 12 }} className="text-left">
                    <div className="mb-0" style={{ fontSize: '10px' }}>
                      I consent to receive calls and texts to any phone number I provide for any purpose, including marketing, under these
                      {' '}
                      <span className="text-primary text-link" onClick={toggleTCPAModal}>terms</span>
                      .
                    </div>
                  </Col>
                  )}
                  <Col xs={12} className="text-center mt-2">
                    <Button
                      color="primary"
                      size="lg"
                      id="SubmitLoanSelection"
                      onClick={handleFinalizeSale}
                      isLoading={isLoading}
                      className="w-100 text-13"
                    >
                      TEXT MY PHONE TO PROCEED
                    </Button>
                  </Col>
                  {get(response, 'requestConsent')
                  && (
                  <Col sm={{ size: 12 }} className="text-left mt-2">
                    <div className="mb-0" style={{ fontSize: '10px' }}>
                      By clicking &#34;TEXT MY PHONE TO PROCEED&#34;, I hereby agree and consent to the
                      {' '}
                      <span className="text-primary text-link" onClick={toggleDisclousureModal}>terms, conditions, acknowledgements and disclosures</span>
                      {' '}
                      and to the
                      {' '}
                      <span className="text-primary text-link" onClick={toggleElectronicModal}>Electronic Consent</span>
                      .
                    </div>
                  </Col>
                  )}
                  {get(response, 'requestConsent')
                  && (
                  <Col sm={{ size: 12 }} className="text-left mt-1">
                    <div className="mb-0" style={{ fontSize: '10px' }}>
                      Important information about procedures for opening a new account;
                      <span> </span>
                      <span id="patriot-popover" className="text-primary text-link" onClick={() => setIsPatriotActPopoverOpen(!isPatriotActPopoverOpen)}>USA PATRIOT Act Notice</span>
                      <Popover
                        isOpen={isPatriotActPopoverOpen}
                        placement="top"
                        target="patriot-popover"
                        toggle={() => setIsPatriotActPopoverOpen(!isPatriotActPopoverOpen)}
                      >
                        <div className="px-2 pt-2 pb-1 m-auto">
                          <p className="mb-1">To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.</p>
                          <p className="mb-0">What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver&apos;s license or other identifying documents.</p>
                        </div>
                      </Popover>
                    </div>
                  </Col>
                  )}
                  <p className="text-center ml-auto mr-auto mt-2 mb-0">
                    Need Help? Call (888) 501-1717
                  </p>
                </Row>
              </ModalBody>
            </Modal>
            <Modal isOpen={isTCPAModalVisible} toggle={toggleTCPAModal} size="lg">
              <ModalHeader toggle={toggleTCPAModal}>TCPA Consent</ModalHeader>
              <ModalBody style={{ maxHeight: 600, overflow: 'auto' }}>
                <div style={{ fontSize: '0.875rem' }}>
                  By providing the telephone number above and clicking &#34;TEXT MY PHONE TO PROCEED&#34;, you confirm you are the owner of the phone number(s), or that the owner authorized you to provide the phone number(s), or that you are the regular user of this phone number(s). You are providing express written consent via your electronic signature to receive any type of communication for any purpose, including telemarketing communications, from our
                  {' '}
                  <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lending partners</a>
                  , LendingUSA, their agents, representatives, affiliates, assignees or anyone calling on their behalf, at the telephone number(s) provided for any lawful purpose, even if that number is on a federal or state do-not-call list. The scope of this consent includes, but is not limited to, receiving live operator calls, calls and texts via an automatic telephone dialing system (auto-dialer), prerecorded and artificial voice messages, text/SMS messages or other means. These telephone calls and text messages may incur message, data and access fees from your telephone provider. Consent is not a condition to obtain a loan.
                  <br />
                  <br />
                  You understand that you can request a paper copy of these terms or may withdraw your consent at any time by:
                  <br />
                  <ul>
                    <li>mailing your written request to LendingUSA c/o Compliance Department at 15303 Ventura Blvd., Suite 850, Sherman Oaks, CA 91403;</li>
                    <li>
                      by e-mailing
                      {' '}
                      <a href="mailto:customercare@lendingusa.com">customercare@lendingusa.com</a>
                      ; or
                    </li>
                    <li>by calling LendingUSA at (800) 994-6177.</li>
                  </ul>
                  You may also reply &#34;STOP&#34; to opt out of receiving autodialed text messages only.
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={isDisclosureModalVisible} toggle={toggleDisclousureModal} size="lg">
              <ModalHeader toggle={toggleDisclousureModal}>Terms, conditions, acknowledgements and disclosures</ModalHeader>
              <ModalBody style={{ maxHeight: 600, overflow: 'auto' }}>
                <div style={{ fontSize: '0.875rem' }}>
                  By clicking &#34;TEXT MY PHONE TO PROCEED&#34; or other links assenting to our terms, you acknowledge that you agreed that any data that you provided to our third-party partner may be transferred to LendingUSA, LLC (&#34;LendingUSA&#34;) and these
                  {' '}
                  <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lenders</a>
                  {' '}
                  (each a &#34;Lender&#34;) for the processing of your loan application (&#34;Application&#34;).  You also authorize LendingUSA to use such data in accordance with its
                  {' '}
                  <a href="https://www.lendingusa.com/privacy-policy">privacy policy</a>
                  . You further authorize LendingUSA, Lender, and their affiliated third parties (&#34;Third Parties&#34;) to forward your Application, including your non-public personal information, to third parties to verify the information contained in your Application, including, but not limited to the following: (1) contacting your employer(s) to verify employment and income; (2) contacting your Merchant Provider (if applicable) to verify any of the following information: (i) the type of procedure(s), service(s) and/or product(s) to be provided; (ii) the date of service; (iii) cost of the goods and/or services to be provided. You acknowledge that you have reviewed and agree to our Privacy Policy which can be found at
                  {' '}
                  <a href="https://www.lendingusa.com/privacy-policy">https://www.lendingusa.com/privacy-policy</a>
                  {' '}
                  and you have reviewed and agree to our Terms of Use which can be found at
                  {' '}
                  <a href="https://www.lendingusa.com/terms-of-use">https://www.lendingusa.com/terms-of-use</a>
                  .
                  <br />
                  <br />
                  You understand that APRs will vary depending upon credit ratings and/or the payment terms that are approved. Credit approvals are valid for a limited time only. You understand that checking your rate and reviewing loan offers on LendingUSA&#39;s Text-to-Apply mobile platform, merchant portal, or any other LendingUSA affiliated online platform results in a soft credit inquiry which does not affect your credit score. However, if you receive a preapproval (“Offer“) from LendingUSA, and you select an Offer provided to you and proceed with acquiring a loan from the Lender, then LendingUSA and/or Lender will request a full credit report from one or more of the credit reporting agencies. This request will appear as a hard inquiry on your credit report and may affect your credit score. You further acknowledge and understand that additional soft credit inquiry reports may be requested and obtained from one or more of the credit reporting agencies during the application process or throughout the term of your loan. These soft pull inquiries will be requested: (1) to confirm that you continue to meet the Lender&#39;s credit criteria; (2) to prevent potential fraudulent activities; (3) for internal modeling and analysis purposes; (4) in servicing or monitoring any product(s) and/or service(s) you may have attained through LendingUSA and/or its Lender; (5) in collecting, enforcing or selling any loan that you may receive from Lender; (6) in providing your credit data to you; (7) in offering you financial products and services; and/or (8) for any other lawful purpose.
                  <br />
                  <br />
                  You authorize your wireless carrier to disclose information about your account, such as subscriber status, payment method and device details, if available, to support identity verification, fraud avoidance and other uses in support of credit transactions. Information may also be shared with other service providers to further support identity verification and fraud prevention.
                  <br />
                  <br />
                  You further authorize and expressly consent to be contacted by Lender, LendingUSA, their agents, representatives, assignees, affiliated third parties, or anyone calling on their behalf for any and all purposes (collectively &#34;Authorized Callers&#34;). This express consent applies to each such telephone number that you provide to any Authorized Caller, either now or in the future, even if the number is on a federal or state do-not-call list. You confirm you are the owner of the phone number(s), or that the owner authorized you to provide this phone number(s), or that you are the regular user of this phone number(s). You are providing express written consent authorizing any Authorized Caller to contact you at this number for any lawful purposes with a live operator, automatic telephone dialing system (auto-dialer) for both phone calls and text/SMS messages, prerecorded and artificial voice message, text/SMS message or other means.  Authorization may be revoked as described in our Terms of Use.  These telephone calls and text messages may incur message, data and access fees from your telephone provider.
                  <br />
                  <br />
                  Electronic Signature Agreement (&#34;Agreement&#34;). By clicking the &#34;TEXT MY PHONE TO PROCEED&#34; button, you are consenting to this Agreement and signing your Application electronically. You agree your electronic signature is the legal equivalent of your manual signature on this Application. By clicking &#34;TEXT MY PHONE TO PROCEED&#34;, you consent to be legally bound by LendingUSA&#39;s terms and conditions. You further agree that your use of a key-pad, mouse, touch pad or other device to select an item, button, icon or similar act/action online, through LendingUSA&#39;s Text-to-Apply mobile platform, merchant portal, or any other LendingUSA affiliated online platform constitutes your signature (hereafter referred to as &#39;E-Signature&#39;). You also agree that no third-party verification is necessary to validate your E-Signature, and that the lack of such third-party verification will not in any way affect the enforceability of your E-Signature, or of any resulting agreement between you and LendingUSA.
                  <br />
                  <br />
                  By clicking on &#34;TEXT MY PHONE TO PROCEED&#34; and continuing with this Application, you certify that: 1) you are the Applicant; 2) you are over the age of 18 years; 3) you are residing in the United States and; 4) all information that has been provided by you on this Application is true and accurate.
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={isElectronicModalVisible} toggle={toggleElectronicModal} size="lg">
              <ModalHeader toggle={toggleElectronicModal}>Electronic Consent</ModalHeader>
              <ModalBody style={{ maxHeight: 600, overflow: 'auto' }}>
                <div style={{ fontSize: '0.875rem' }}>
                  <b>CONSENT FOR ELECTRONIC SIGNATURES, RECORDS, AND DISCLOSURES (&#34;E-Consent&#34;)</b>
                  <br />
                  Please read this information carefully and print a copy and/or retain this information electronically for future reference.
                  <br />
                  <br />
                  <u><b>Introduction.</b></u>
                  {' '}
                  You are using this &apos;Portal&apos; or &apos;Website&apos; to submit a request for consumer financial services from these
                  {' '}
                  <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lenders</a>
                  {' '}
                  (each a &#34;Bank&#34;), LendingUSA as operator of the Portal, and their assignees (hereinafter &#34;we,&#34; &#34;us,&#34; &#34;our,&#34; or &#34;Bank&#34;), among other services. To provide these services, we need your consent to using and accepting electronic signatures, records, and disclosures (&#34;E-Consent&#34;). This E-Consent notifies you of your rights when receiving disclosures, notices, and information from the Bank or LendingUSA. By clicking links or buttons assenting to our terms (&#34;Links&#34;), you agree that you received this E-Consent and that you consent to using electronic signatures, records, and disclosures. Additionally, by clicking Links, you consent to conduct transactions by using electronic disclosures, electronic records, and contract documents (&#34;Disclosures&#34;).
                  <br />
                  <br />
                  <u>Option for Paper or Non-Electronic Records.</u>
                  {' '}
                  You may request any Disclosures in paper copy by logging in and printing a paper copy. You may also mail your written request to LendingUSA, at the following address: 15303 Ventura Blvd. Suite 850, Sherman Oaks, CA 91403. We will provide paper copies at no charge. The Bank or its assignee will retain all Disclosures as applicable law requires.
                  <br />
                  <br />
                  <u><b>Scope of Consent.</b></u>
                  {' '}
                  This E-Consent applies to all interactions online concerning you and Bank and/or LendingUSA, and includes those interactions engaged in on any computer, electronic device, mobile device, including phones, smart-phones, fax machines, and tablets. Under this E-Consent, Bank and/or LendingUSA will process your information and interact during all online interactions with you electronically. The Disclosures you will receive electronically include federal disclosures, such as the Truth-in-Lending disclosures, Privacy Notices, and notices of our credit decisions, as well as state-specific notices. We will also send you notices electronically related to our interactions and transactions. This E-Consent informs you of your rights when receiving these Disclosures electronically.
                  <br />
                  <br />
                  <u><b>Consenting to Do Business Electronically.</b></u>
                  {' '}
                  Before you decide to do business electronically with Bank and/or LendingUSA, you should consider whether you have the required hardware and software capabilities described below.
                  <br />
                  <br />
                  <u><b>Hardware and Software Requirements.</b></u>
                  {' '}
                  To access and retain the Disclosures electronically, you will need to use a computer or device capable of accessing the Internet and an Internet Browser software program that supports at least 256-bit encryption, such as Microsoft® Internet Explorer 11+, Safari 7+ and the evergreen Chrome, Firefox or Edge. To read some documents, you may need a PDF file reader like Adobe® Acrobat Reader, Xpdf® or Foxit®. If these requirements change while you are maintaining an active relationship with Bank, and the change creates a material risk that you may not be able to receive Disclosures electronically, Bank will notify you of these changes. You will need a printer or a long-term storage device, such as your computer&apos;s disk drive, to retain a copy of the Disclosures for future reference. You may send us your written questions regarding the hardware and software requirements to:
                  <br />
                  <br />
                  <b>Email</b>
                  <br />
                  CustomerCare@LendingUSA.com
                  <br />
                  <br />
                  <b>Call</b>
                  <br />
                  Our regular business hours are 9:00am to 5:00pm PST
                  <br />
                  800-994-6177
                  <br />
                  <br />
                  <b>Address for Regular Mail</b>
                  <br />
                  15303 Ventura Blvd., Suite 850
                  <br />
                  Sherman Oaks, CA 91403
                  <br />
                  <br />
                  <u><b>Withdrawing Consent.</b></u>
                  {' '}
                  You are free to withdraw this E-Consent at any time and at no charge. However, if you withdraw this E-Consent before receiving consumer financial services, this will prevent you from obtaining consumer financial services from us. If at any time you wish to withdraw this E-Consent, you can send us your written request by e-mail to CustomerCare@LendingUSA.com with the details of such request. If you decide to withdraw this E-Consent, the withdrawal will not affect the legal effectiveness, validity, and enforceability of prior electronic Disclosures.
                  <br />
                  <br />
                  <u><b>Change to Your Contact Information.</b></u>
                  {' '}
                  You agree to keep us informed of any change in your electronic address or mailing address. You may update such information by calling us or emailing us to provide the updated information. You may also send us your written update by mail to our address above.
                  <br />
                  <br />
                  <u><b>YOUR ABILITY TO ACCESS DISCLOSURES.</b></u>
                  {' '}
                  BY CLICKING LINKS, YOU ASSENT TO OUR TERMS. YOU ACKNOWLEDGE AND REPRESENT THAT YOU CAN ACCESS THE DISCLOSURES IN THE DESIGNATED FORMATS DESCRIBED ABOVE. Once you give your consent, you can log into the website to access these documents.
                  <br />
                  <br />
                  <u><b>CONSENT.</b></u>
                  {' '}
                  BY CLICKING LINKS, YOU ASSENT TO OUR TERMS. YOU ACKNOWLEDGE YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS, DISCLOSURES, AND DOING BUSINESS ELECTRONICALLY. YOU CONSENT TO USING ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM, AND TO DOING BUSINESS WITH US ELECTRONICALLY. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WE WILL PROVIDE TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN YOU NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE THAT YOUR CONSENT TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE CONSUMER FINANCIAL SERVICES FROM US OVER THE INTERNET.
                  <br />
                  <br />
                  Print and retain a hard copy or electronic copy.
                  <br />
                  <br />
                  <b>ELECTRONIC CONSENT AND ACKNOWLEDGMENT.</b>
                  {' '}
                  BY CLICKING LINKS, YOU CERTIFY THAT:
                  <br />
                  <ul>
                    <li>Your computer hardware and software meet the requirements needed to access and retain the Disclosures electronically.</li>
                    <li>You consent to receive the Disclosures electronically.</li>
                    <li>You consent to sign your Loan Agreement and related documents electronically.</li>
                    <li>You understand and agree that you will be bound to the same terms and conditions if you sign the Disclosures electronically as if you signed paper Disclosures.</li>
                  </ul>
                </div>
              </ModalBody>
            </Modal>
            { errorMessage
              && (
              <Modal
                isOpen={!!errorMessage}
                backdrop="static"
                centered
                size="md"
              >
                <ModalHeader>
                  Unable to Save Funding Account
                </ModalHeader>
                <ModalBody className="text-center">
                  <img src={imgError} alt="" className="mb-1" />
                  <p>{errorMessage}</p>
                  <Button
                    className="w-100"
                    onClick={handleTryAgain}
                    color="danger"
                  >
                    TRY AGAIN
                  </Button>
                </ModalBody>
              </Modal>
              )}
          </Container>
        </div>
      )
      : (
        <div className="loading-container">
          <ReactLoading color="#3989E0" width={100} height={100} type="spin" />
        </div>
      )
  );
};

FundingAccount.propTypes = {
  history: PropTypes.object.isRequired,
  nextAction: PropTypes.func.isRequired,
  checkPreviousAction: PropTypes.func.isRequired,
  validateRoutingNumberAction: PropTypes.func.isRequired,
  workflow: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  match: PropTypes.object.isRequired,
  validator: PropTypes.object.isRequired,
  ipAddress: PropTypes.string.isRequired,
};

export default compose(
  Validator(schema),
  withRouter,
  connect(
    (state) => ({
      workflow: state.workflow,
    }),
    {
      nextAction,
      updateOffer,
      checkPreviousAction,
      validateRoutingNumberAction,
    }
  )
)(FundingAccount);
