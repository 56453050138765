import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const Declined = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const params = parseUrlParams(window.location.search);

    const { history } = props;

    if (!params.key) {
      history.push(`/applications/${props.match.params.workflowtype}/application`);
    }
  }, []);

  return (
    <div className="page-preapproved narrow">
      <Container>
        <Row className="mt-md-3 mb-2 mb-md-4 justify-content-center">
          <Col md={10} lg={9} xl={7}>
            <p className="mb-0"><strong className="text-primary">Step 3 of 3</strong></p>
            <p className="mb-1">
              <b>Application ID:</b>
              {' '}
              {get(props.workflow, 'attributes.entityId')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
            <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
            <h2 className="mb-2">Unfortunately, your application was not approved at this time.</h2>
            <p className="mb-4">You will receive an adverse action notice within 30 days that will provide you with the specific reason(s) as to why your application was not approved.</p>
            {
              props.match.params.workflowtype === 'dtc'
                && (
                <Row>
                  <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
                    <h4 className="mb-4 text-primary">Please contact your merchant for other financing options:</h4>
                    <Card className="dark-shadow">
                      <CardBody>
                        <h4>{get(props.workflow, 'data.data.merchantDisplayName')}</h4>
                        <p className="mb-0">
                          {get(props.workflow, 'data.data.merchantAddress.street')}
                          .
                          <br />
                          {get(props.workflow, 'data.data.merchantAddress.city')}
                          ,
                          {' '}
                          {get(props.workflow, 'data.data.merchantAddress.state')}
                          {' '}
                          {get(props.workflow, 'data.data.merchantAddress.zipCode')}
                          <br />
                          {formatPhoneNumber(get(props.workflow, 'data.data.merchantPhoneNumber') || '')}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                )
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Declined.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
};

Declined.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
  workflow: state.workflow,
}))(Declined);
