import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';
import circleDeclined from 'assets/icons/circle-declined.svg';

const DuplicationDeclined = (props) => {
  useEffect(() => {
    const params = parseUrlParams(window.location.search);
    if (!params.key) {
      props.history.push(`/applications/${props.match.params.workflowtype}/application`);
    }
  }, []);

  const handleMerchantReturnClick = (e) => {
    e.preventDefault();
    const params = parseUrlParams(window.location.search);
    if (params.key) {
      props.history.push('/dashboard');
    }
  };

  return (
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
          <img src={circleDeclined} alt="Circle Declined" className="mb-3" />
          <h2>Unfortunately, your request for credit cannot be approved due to you having an existing credit request on file. You are welcome to resubmit a credit request after 30 days from the date you submitted your existing credit request.</h2>
          {
            localStorage.getItem('token') && (
              <Button
                color="primary"
                size="lg"
                className="mt-4 large arrow"
                onClick={handleMerchantReturnClick}
              >
                Merchant Return to Portal
              </Button>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

DuplicationDeclined.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

DuplicationDeclined.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
}))(DuplicationDeclined);
