import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';
import circleDeclined from 'assets/icons/circle-declined.svg';
import logo from 'assets/images/logo.svg';

import './style.scss';

const NewDeclinedPage = (props) => {
  useEffect(() => {
    const params = parseUrlParams(window.location.search);
    if (!params.key) {
      props.history.push(`/applications/${props.match.params.workflowtype}/application`);
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
          <img src={circleDeclined} alt="Circle Declined" className="mb-3" />
          <h3>Your application is currently being reviewed</h3>
          <p className="p-small">One of our representatives will verify some additional information and inform you of our decision</p>
        </Col>
        <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
          <h3>
            <span className="y-color">Next Step:</span>
            {' '}
            Please Call (888) 502-8027
          </h3>
          <img src={logo} alt="Lendingusa Logo" />
        </Col>
      </Row>
    </Container>
  );
};

NewDeclinedPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

NewDeclinedPage.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
}))(NewDeclinedPage);
