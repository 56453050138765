import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'reactstrap';
import Sticky from 'react-stickynode';

import { appConfig } from 'config/appConfig';

const Header = (props) => {
  const { envLabel } = appConfig;
  const { step } = props;

  return (
    // Global Header
    <Sticky
      enabled
      innerZ={20}
    >
      <div className="dtc-header">
        <Navbar light expand="lg" className="justify-content-between">
          <div className="d-flex align-items-center position-relative">
            <NavbarBrand className="text-hide" href="/">LendingUSA Portal</NavbarBrand>
            { envLabel && envLabel !== 'production' && <div className="envLabel">{ envLabel }</div> }
          </div>
          <div className="text-right phone">
            <h5>Questions?</h5>
            <h4 className="mb-0 text-danger">(888) 501-1717</h4>
          </div>
        </Navbar>
        {
          step && (
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${step}%` }} />
            </div>
          )
        }
      </div>
    </Sticky>
    // End Global Header
  );
};

Header.propTypes = {
  step: PropTypes.any,
};

Header.defaultProps = {
  step: null,
};

export default Header;
