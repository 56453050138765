import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  nextAction,
} from 'actions/workflow';
import { parseUrlParams } from 'utils/parseUrlParams';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const CongratulationsDTM = (props) => {
  const { workflow } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    const { history } = props;
    const params = parseUrlParams(window.location.search);

    if (get(workflow, 'data.data') === undefined) {
      history.push(`/applications/dtc1/checkin?key=${params.key || ''}`);
    }

    if (!params.key) {
      history.push('/');
    }
  }, []);

  return (
    <div className="page-preapproved narrow">
      <Container>
        <Row className="mt-md-3 mb-2 mb-md-4 justify-content-center">
          <Col md={10} lg={9} xl={7}>
            <p className="mb-1"><strong className="text-primary">Step 3 of 3</strong></p>
            <p className="mb-1">
              <b>Application ID:</b>
              {' '}
              {get(props.workflow, 'attributes.entityId')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-2 mt-2 mt-md-4">
            <img src="/icons/pre-approved.svg" alt="Pro-Approved" className="mb-3" />
            <h2 className="mb-4 text-align-center">
              Congratulations!
              <br />
              {' '}
              Your Loan is Pre-Approved
            </h2>
            <Row>
              <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                <h4 className="mb-4 text-primary">
                  Please contact your merchant
                  <br />
                  {' '}
                  for next steps:
                </h4>
                <Card className="dark-shadow">
                  <CardBody>
                    <h4>{get(workflow, 'data.data.merchantDisplayName')}</h4>
                    <p className="mb-0">
                      {get(workflow, 'data.data.merchantAddress.street')}
                      .
                      <br />
                      {get(workflow, 'data.data.merchantAddress.city')}
                      ,
                      {' '}
                      {get(workflow, 'data.data.merchantAddress.state')}
                      {' '}
                      {get(workflow, 'data.data.merchantAddress.zipCode')}
                      <br />
                      {formatPhoneNumber(get(workflow, 'data.data.merchantPhoneNumber') || '')}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CongratulationsDTM.propTypes = {
  history: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    auth: state.auth,
    workflow: state.workflow,
  }),
  {
    nextAction,
  }
)(CongratulationsDTM);
