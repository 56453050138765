export const unSupportedStates = [
  'CT',
  'IA',
  'MA',
  'ND',
  'NH',
  'VT',
  'WV',
  'PR',
];
