import { call, takeEvery } from 'redux-saga/effects';
import { WorkflowConstants } from 'constants/workflowConstants';
import { request } from 'components/Request';

function* checkinAction(action) {
  yield call(request({
    type: WorkflowConstants.CHECKIN_ACTION,
    method: 'POST',
    url: action.payload.url,
    apiUrl: action.payload.apiUrl,
  }), action);
}

function* nextAction(action) {
  yield call(request({
    type: WorkflowConstants.NEXT_ACTION,
    method: 'PUT',
    url: action.payload.url,
  }), action);
}

function* updateOffer(action) {
  yield call(request({
    type: WorkflowConstants.UPDATE_OFFER,
    method: 'PUT',
    url: action.payload.url,
  }), action);
}

function* checkPrviousAction(action) {
  yield call(request({
    type: WorkflowConstants.PREVIOUS_ACTION,
    method: 'PUT',
    url: action.payload.url,
  }), action);
}

function* getIPAddress(action) {
  yield call(request({
    type: WorkflowConstants.GET_IP_ADDRESS,
    method: 'GET',
    nullData: true,
    apiUrl: 'https://api.ipify.org?format=json&callback=?',
  }), action);
}

function* getAffDetails(action) {
  yield call(request({
    type: WorkflowConstants.GET_AFF_DETAILS,
    method: 'GET',
    url: action.payload.url,
  }), action);
}

function* getKeyAction(action) {
  yield call(request({
    type: WorkflowConstants.GET_KEY_ACTION,
    method: 'GET',
    url: action.payload.url,
    apiUrl: action.payload.apiUrl,
  }), action);
}

function* checkDuplication(action) {
  yield call(request({
    type: WorkflowConstants.GET_KEY_ACTION,
    method: 'GET',
    url: '/workflows/dtc/check-duplication',
  }), action);
}

function* resumeApplicationSaga(action) {
  yield call(request({
    type: WorkflowConstants.RESUME_APPLICATION,
    method: 'PUT',
    url: `/workflows/${action.payload.workflowType}/${action.payload.key}/step/continuefromcreditfreeze`,
  }), action);
}

function* validateRoutingNumberSaga(action) {
  yield call(request({
    type: WorkflowConstants.VALID_ROUTING_NUMBER,
    method: 'GET',
    url: `/workflows/dtc/bank-accounts/routing-number/${action.payload.routingNumber}`,
  }), action);
}

const WorkflowSaga = function* () {
  yield takeEvery(WorkflowConstants.CHECKIN_ACTION, checkinAction);
  yield takeEvery(WorkflowConstants.NEXT_ACTION, nextAction);
  yield takeEvery(WorkflowConstants.UPDATE_OFFER, updateOffer);
  yield takeEvery(WorkflowConstants.PREVIOUS_ACTION, checkPrviousAction);
  yield takeEvery(WorkflowConstants.GET_IP_ADDRESS, getIPAddress);
  yield takeEvery(WorkflowConstants.GET_AFF_DETAILS, getAffDetails);
  yield takeEvery(WorkflowConstants.GET_KEY_ACTION, getKeyAction);
  yield takeEvery(WorkflowConstants.CHECK_DUPLICATION, checkDuplication);
  yield takeEvery(WorkflowConstants.RESUME_APPLICATION, resumeApplicationSaga);
  yield takeEvery(WorkflowConstants.VALID_ROUTING_NUMBER, validateRoutingNumberSaga);
};

export default WorkflowSaga;
