import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import comodo from 'assets/images/comodo.png';

const Sidebar = () => (
  /* <!-- Apply Sidebar --> */
  <Col lg={4}>
    <div className="apply-sidebar sticky-top pt-3 mb-2 ml-lg-3" style={{ top: 150, zIndex: 1 }}>
      <Card className="text-center border-light">
        <CardBody>
          <Row>
            <Col xs={12} className="mt-1 mb-3">
              <img src="/icons/apply-step-1.svg" alt="" className="mb-1" />
              <h5>Complete our fast and easy loan application</h5>
            </Col>
            <Col xs={12} className="mb-3">
              <img src="/icons/apply-step.svg" alt="" className="mb-1" />
              <h5>Fixed Rates and Low Monthly Payments</h5>
            </Col>
            <Col xs={12}>
              <img src="/icons/apply-step-3.svg" alt="" className="mb-1" />
              <h5>Get a loan decision in seconds!</h5>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col>
          <img src="/icons/secure.svg" alt="SSL Secure" className="float-left mr-1" />
          <p style={{ lineHeight: 1.2 }}>
            <small>
              <strong>128-bit SSL</strong>
              {' '}
              protection and strict encryption
            </small>
          </p>
        </Col>
        <Col xs="auto">
          <img src={comodo} alt="Comodo Secure" className="setigo-image" />
        </Col>
      </Row>
    </div>
  </Col>
);

export default Sidebar;
