import keyMirror from 'keymirror';

/**
 * @constant {Object} LookupConstants
 * @memberof Lookup
 */
export const LookupConstants = keyMirror({
  FETCH_RENT_OR_OWN: undefined,
  FETCH_EMPLOYMENT_YEARS: undefined,
  FETCH_EMPLOYMENT_STATUS: undefined,
  GET_TEST_VALUES_DATA: undefined,
});
