import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIPAddress } from 'actions/workflow';

export default function withIPAddress(WrappedComponent) {
  const HOC = (props) => {
    const [ipAddress, setIpAddress] = useState(null);

    useEffect(() => {
      props.getIPAddress({
        data: {},
        success: (ipResponse) => {
          setIpAddress(ipResponse.ip);
        },
        fail: () => {
          console.log('Getting IP address failed');
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      props.getIPAddress({
        data: {},
        success: (ipResponse) => {
          setIpAddress(ipResponse.ip);
        },
        fail: () => {
          console.log('Getting IP address failed');
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location]);

    return <WrappedComponent {...props} ipAddress={ipAddress || 'Not able to detect IP Address!'} />;
  };

  HOC.propTypes = {
    getIPAddress: PropTypes.func.isRequired,
    location: PropTypes.object,
  };

  HOC.defaultProps = {
    location: null,
  };

  return connect(null, { getIPAddress })(HOC);
}
