import React, { useState } from 'react';
import validate from 'web-form-validator';
import { setDeepValue } from 'utils/objects';

const ValidadorComponent = (schema, formData) => (WrappedComponent) => {
  const Validator = (props) => {
    const [values, setValues] = useState(formData || {});
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(true);

    const onChangeHandler = (name, value) => {
      let clonedValues = { ...values };
      const clonedErrors = { ...errors };

      if (name.split('.').length > 1) {
        clonedValues = setDeepValue({ ...clonedValues }, name, value);
      } else {
        clonedValues[name] = value;
      }

      if (Object.prototype.hasOwnProperty.call(clonedErrors, name)) {
        delete clonedErrors[name];
      }

      setErrors(clonedErrors);
      setValues(clonedValues);
      setIsValid(Object.keys(clonedErrors).length === 0);
    };

    // Use this method to set values on componentWillMount
    // if user already entered some data before
    const setValuesData = (valuesData) => {
      typeof valuesData === 'object' && setValues(valuesData);
    };

    const setErrorsData = (errorsData = {}) => {
      const newErrors = { ...errors, ...errorsData };
      setErrors(newErrors);
    };

    const resetErrors = () => {
      setErrors({});
      setIsValid(true);
    };

    // User this methond on detele multiple errors e.g. ['password', 'passwordConfirmation']
    // to remove errors from all related inputs on changing the only one of them
    const deleteErrors = (names = []) => {
      const clonedErrors = { ...errors };
      names.forEach((name) => (
        delete clonedErrors[name]
      ));
      setErrors(clonedErrors);
      setIsValid(Object.keys(clonedErrors).length === 0);
    };

    const validateForm = (customSchema = schema) => {
      const clonedValues = { ...values };
      const {
        errors: validationErrors,
        isValid: isFormValid,
      } = validate(customSchema, clonedValues);
      setErrors(validationErrors);
      setIsValid(isFormValid);
      return { isValid: isFormValid };
    };

    return (
      <WrappedComponent
        validator={{
          validate: validateForm,
          setValues: setValuesData,
          setErrors: setErrorsData,
          deleteErrors,
          resetErrors,
          onChangeHandler,
          values,
          errors,
          isValid,
        }}
        {...props}
      />
    );
  };

  return Validator || null;
};

export default ValidadorComponent;
