import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components/Heading';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import style from './style.scss';

const Denied = (props) => {
  const { className } = props;

  return (
    <form className={cn(style.App, className)} onSubmit={() => {}}>
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
            <Heading
              heading="Unfortunately, we were unable to approve your application at this time"
              subHeading={(
                <>
                  You will receive an adverse action notice within 30 days that will
                  <br />
                  provide you with the specific reason(s) as to why we were unable to
                  <br />
                  approve your application.
                </>
)}
              className={style.heading}
              size="p-xlarge"
            />
          </Col>
        </Row>
      </Container>
    </form>
  );
};

Denied.propTypes = {
  className: PropTypes.string,
};

Denied.defaultProps = {
  className: '',
};

export default Denied;
