import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

const NotValid = () => (
  <div className="page-preapproved narrow">
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
          <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
          <h2 className="mb-2">This link is no longer valid.</h2>
        </Col>
      </Row>
    </Container>
  </div>
);

NotValid.propTypes = {
};

NotValid.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
  workflow: state.workflow,
}))(NotValid);
