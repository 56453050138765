import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import './styles.scss';

const LifeSavers = () => (
  <Col lg={4} className="life-saver">
    <div className="sticky-top pt-3 mb-2 ml-lg-3" style={{ top: 150, zIndex: 1 }}>
      <Card>
        <CardBody>
          <Row>
            <Col xs={12} className="mt-1 mb-3 border-bottom">
              <img src="/icons/five-stars.svg" alt="" className="mb-1" />
              <p>LendingUSA is a great online way to get a personal loan with highly qualified customer service. From the quote rate to filling out online application to getting your loan is very efficient I highly recommend this company.</p>
              <p><small><b>Lydia</b></small></p>
            </Col>
            <Col xs={12} className="mb-3 border-bottom">
              <img src="/icons/five-stars.svg" alt="" className="mb-1" />
              <p>My loan was approved within an hour, and customer service was superb. The process was done online, and it was fast. I am very satisfied.</p>
              <p><small><b>Rita</b></small></p>
            </Col>
            <Col xs={12}>
              <img src="/icons/five-stars.svg" alt="" className="mb-1" />
              <p>My experience with LendingUSA was a very pleasant one. The person that contacted me was very polite and friendly. Customer service was great, answering all the questions I had from the start. The whole experience was quick and painless. Thank you.</p>
              <p><small><b>Anna</b></small></p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  </Col>
);

export default LifeSavers;
