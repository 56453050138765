import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'components/Button';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import { parseUrlParams } from 'utils/parseUrlParams';
import circleBlocked from 'assets/icons/circle-declined.svg';

import './style.scss';

const Blocked = (props) => {
  useEffect(() => {
    const params = parseUrlParams(window.location.search);
    if (!params.key) {
      props.history.push(`/applications/${props.match.params.workflowtype}/application`);
    }
  }, []);

  const handleMerchantReturnClick = (e) => {
    e.preventDefault();
    const params = parseUrlParams(window.location.search);
    if (params.key) {
      props.history.push('/dashboard/application-review');
    }
  };

  return (
    <div className="page-preapproved narrow">
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} className="text-center mb-2 mt-2 mt-md-4">
            <img src={circleBlocked} alt="Circle Blocked" />
            <h2 className="pt-3">Unfortunately, we were unable to approve your application at this time.</h2>
            <Col xs={12}>
              <div className="pt-3 mt-1">
                <Col lg={{ size: 8, offset: 2 }}>
                  {
                    localStorage.getItem('token') && (
                      <Button
                        className="button w-100 large arrow green"
                        onClick={handleMerchantReturnClick}
                      >
                        Merchant Return to Portal
                      </Button>
                    )
                  }
                </Col>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Blocked.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

Blocked.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
}))(Blocked);
