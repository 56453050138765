import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  nextAction,
} from 'actions/workflow';
import { parseUrlParams } from 'utils/parseUrlParams';
import { Button } from 'components/Button';
import cn from 'classnames';
import './style.scss';

const Congratulations = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { history } = props;
    const params = parseUrlParams(window.location.search);
    if (!params.key) {
      history.push('/');
    }
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    const params = parseUrlParams(window.location.search);
    setIsLoading(true);
    if (params.key) {
      props.nextAction({
        data: {},
        url: `/workflows/dtc/${params.key}/next`,
        success: (response) => {
          setIsLoading(false);
          const routeUrl = response.data && response.data.url;
          props.history.push(routeUrl);
        },
        fail: (error) => {
          props.history.push({
            pathname: `/applications/${props.match.params.workflowtype}/general-error-page`,
            search: '',
            state: {
              data: error.data,
            },
          });
        },
      });
    }
  };

  return (
    <Container fluid className="congratulations">
      <Row>
        <Col md={10} lg={9} xl={7}>
          <p className="mb-0"><strong className="text-primary">Step 3 of 3</strong></p>
          <p className="mb-1">
            <b>Application ID:</b>
            {' '}
            {get(props.workflow, 'attributes.entityId')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mb-2 mt-2 mt-md-4 col">
          <img src="/icons/congrats.svg" alt="Pro-Approved" className="mb-3" />
          <p className="mb-4 text-align-center congrats-title">
            Congratulations!
            <br />
            <b>Your Loan Has Been Pre-Approved</b>
          </p>

          {
            get(props.workflow, 'data.data.isDtc') ? (
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button
                    className={cn('large green', isLoading ? '' : 'arrow')}
                    onClick={handleButtonClick}
                    isLoading={isLoading}
                    color="primary"
                    size="lg"
                  >
                    Review Your Loan Offers now
                  </Button>
                </Col>
              </Row>
            ) : (
              <Container fluid>
                <Row>
                  <Col className="text-center mb-2 mt-2 mt-md-4 col-6 offset-3">
                    <p className="mb-4 text-align-center">
                      To finalize your application please contact our merchant engagement team at 800-994-6177 ext 2.
                    </p>
                  </Col>
                </Row>
              </Container>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

Congratulations.propTypes = {
  history: PropTypes.object.isRequired,
  nextAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    auth: state.auth,
    workflow: state.workflow,
  }),
  {
    nextAction,
  }
)(Congratulations);
