import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch, withRouter, useHistory } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import GuardedRoute from 'components/GuardedRoute';
import { appConfig } from 'config/appConfig';

import Application from './pages/BorrowerApply/InternalPages/Application';
import FindMerchant from './pages/BorrowerApply/InternalPages/FindMerchant';
import CheckingApplication from './pages/BorrowerApply/InternalPages/CheckingApplication';
import Congratulations from './pages/BorrowerApply/InternalPages/Congratulations';
import CongratulationsDTM from './pages/BorrowerApply/InternalPages/CongratulationsDTM';
import EvenDeclined from './pages/BorrowerApply/InternalPages/EvenDeclined';
import FSLDeclined from './pages/BorrowerApply/InternalPages/FSLDeclined';
import Denied from './pages/BorrowerApply/InternalPages/Denied';
import OfferSuggest from './pages/BorrowerApply/InternalPages/OfferSuggest';
import FundingAccount from './pages/BorrowerApply/InternalPages/FundingAccount';
import TPOfferWall from './pages/BorrowerApply/InternalPages/TPOfferWall';
import UnifiedOfferPage from './pages/BorrowerApply/InternalPages/UnifiedOfferPage';
import OfferConfirmation from './pages/BorrowerApply/InternalPages/OfferConfirmation';
import CreditAppDoc from './pages/BorrowerApply/InternalPages/CreditAppDoc';
import Complete from './pages/BorrowerApply/InternalPages/Complete';
import GeneralErrorPage from './pages/BorrowerApply/InternalPages/GeneralErrorPage';
import Declined from './pages/BorrowerApply/InternalPages/Declined';
import CreditFreeze from './pages/BorrowerApply/InternalPages/CreditFreeze';
import NotValid from './pages/BorrowerApply/InternalPages/NotValid';
import Blocked from './pages/BorrowerApply/InternalPages/Blocked';
import DuplicationDeclined from './pages/BorrowerApply/InternalPages/DuplicationDeclined';
import NewDeclinedPage from './pages/BorrowerApply/InternalPages/NewDeclinedPage';
import Redirection from './pages/BorrowerApply/InternalPages/Redirection';
import OfferClicked from './pages/BorrowerApply/InternalPages/OfferClicked';
import withIPAddress from './withIPAddress';
import PickPaymentDate from './pages/BorrowerApply/InternalPages/PickPaymentDate';

import './App.scss'; // eslint-disable-line

const routes = [
  {
    name: 'Borrower',
    pathname: '/',
    component: withIPAddress(Application),
    exact: true,
  },
  {
    name: 'Funeral Borrower',
    pathname: '/funeral',
    component: withIPAddress(Application),
    exact: true,
  },
  {
    name: 'Find Merchant',
    pathname: '/find-merchant',
    component: FindMerchant,
    exact: true,
  },
  {
    name: 'Checking Application',
    pathname: '/applications/:workflowtype/checkin',
    component: CheckingApplication,
    exact: true,
    wrapper: Route,
  },
  {
    name: 'Congratulations',
    pathname: '/:workflowtype/congratulations',
    component: Congratulations,
    exact: true,
  },
  {
    name: 'Congratulations',
    pathname: '/applications/:workflowtype/congratulations',
    component: CongratulationsDTM,
    exact: true,
  },
  {
    name: 'Declined',
    pathname: '/applications/:workflowtype/declined',
    component: FSLDeclined,
    exact: true,
  },
  {
    name: 'Offer Suggest',
    pathname: '/:workflowtype/offer-suggest',
    component: OfferSuggest,
    exact: true,
  },
  {
    name: 'Funding Account',
    pathname: '/payment/funding-account',
    component: withIPAddress(FundingAccount),
    exact: true,
  },
  {
    name: 'Offer Wall',
    pathname: '/:workflowtype/tpoffer',
    component: withIPAddress(TPOfferWall),
    exact: true,
  },
  {
    name: 'Unified Offer Page',
    pathname: '/:workflowtype/unioffer',
    component: withIPAddress(UnifiedOfferPage),
    exact: true,
  },
  {
    name: 'Offer Confirmation',
    pathname: '/:workflowtype/offer-confirmation',
    component: OfferConfirmation,
    exact: true,
  },
  {
    name: 'Credit App Docusign',
    pathname: '/borrowers/:workflowtype/creditappdoc',
    component: CreditAppDoc,
    exact: true,
  },
  {
    name: 'Denied',
    pathname: '/borrowers/:workflowtype/denied',
    component: Denied,
    exact: true,
  },
  {
    name: 'Complete',
    pathname: '/borrowers/:workflowtype/complete',
    component: Complete,
    exact: true,
  },
  {
    name: 'General Error Page',
    pathname: '/borrowers/:workflowtype/general-error-page',
    component: GeneralErrorPage,
    exact: true,
  },
  {
    name: 'Declined',
    pathname: '/:workflowtype/declined',
    component: Declined,
    exact: true,
  },
  {
    name: 'Even Declined',
    pathname: '/:workflowtype/evendeclined',
    component: EvenDeclined,
    exact: true,
  },
  {
    name: 'Credit Freeze',
    pathname: '/:workflowtype/creditfreeze',
    component: CreditFreeze,
    exact: true,
  },
  {
    name: 'Not valid',
    pathname: '/:workflowtype/not-valid',
    component: NotValid,
    exact: true,
  },
  {
    name: 'Blocked',
    pathname: '/:workflowtype/declined2',
    component: Blocked,
    exact: true,
  },
  {
    name: 'Duplication Declined',
    pathname: '/:workflowtype/duplicate-declined',
    component: DuplicationDeclined,
    exact: true,
  },
  {
    name: 'New Declined',
    pathname: '/borrowers/:workflowtype/new-declined',
    component: NewDeclinedPage,
    exact: true,
  },
  {
    name: 'Redirection',
    pathname: '/redirection',
    component: Redirection,
    exact: true,
  },
  {
    name: 'Offer Clicked',
    pathname: '/:workflowtype/offer-clicked',
    component: OfferClicked,
    exact: true,
  },
  {
    name: 'PickPaymentDate',
    pathname: '/:workflowtype/agreement/pick-payment-date',
    component: PickPaymentDate,
    exact: true,
  },
];

export const App = (props) => {
  const history = useHistory();

  const addPageSenseScript = () => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://cdn.pagesense.io/js/lendingusa/${appConfig.pageSenseId}.js`;
    document.head.appendChild(scriptTag);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (appConfig.pageSenseId) {
      addPageSenseScript();
    }
  }, []);

  useEffect(() => {
    const unblock = history.block((newLocation, action) => {
      if (action === 'POP') {
        // eslint-disable-next-line no-alert
        const result = window.confirm('Are you sure you want to leave this page? Changes will not be saved.');
        if (result) history.push('/');
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  const renderHeader = () => {
    const { location: { pathname } } = props;
    let header = <div />;
    switch (pathname) {
      case '/':
        header = <Header step={33} />;
        break;
      case '/find-merchant':
        header = <Header step={67} />;
        break;
      case '/applications/dtc/declined':
      case '/dtc1/declined':
      case '/applications/dtc/congratulations':
      case '/dtc1/congratulations':
        header = <Header step={100} />;
        break;
      default:
        header = <Header />;
    }

    return header;
  };

  const renderFooter = () => {
    const { location: { pathname } } = props;
    let footer = <div />;
    switch (pathname) {
      case '/applications/dtc/declined':
      case '/dtc1/declined':
      case '/applications/dtc/congratulations':
      case '/dtc1/congratulations':
      case '/dtc1/offer-confirmation':
        footer = <Footer pageName="creditOfferConfirmation" />;
        break;
      case '/dtc1/offer-suggest':
        footer = <Footer pageName="offerSuggest" />;
        break;
      case '/find-merchant':
        footer = <Footer pageName="findMerchant" />;
        break;
      default:
        footer = <Footer />;
    }

    return footer;
  };

  return (
    <div className="App bg-white">
      {renderHeader()}
      <div className="container-body">
        <Switch>
          {routes.map((route, index) => (
            <GuardedRoute
              key={index}
              path={route.pathname}
              exact={route.exact || false}
              component={route.component}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </div>
      {renderFooter()}
    </div>
  );
};

App.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(App);
