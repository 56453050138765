// @flow
/**
 * @module Reducers/Workflows
 * @desc Appplication Form Reducer
 */

import { handleActions } from 'redux-actions';
import { WorkflowConstants } from 'constants/workflowConstants';

const initialState = {
  formData: null,
};

export default handleActions({
  [WorkflowConstants.SAVE_APPLICATION_FORM]: (state, action) => ({
    ...state,
    formData: action.payload,
  }),
}, initialState);
