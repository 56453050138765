import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import cn from 'classnames';
import Input from 'components/Form/Input';
import Button from 'components/Button/Button';
import './style.scss';
import { connect } from 'react-redux';
import { parseUrlParams } from 'utils/parseUrlParams';
import { get } from 'lodash';
import moment from 'moment-timezone';
import ApplicationId from 'components/ApplicationId';
import Validator from 'components/Validator/Validator';
import infoIcon from 'assets/images/icon-info.svg';
import { nextAction } from 'actions/workflow';
import schema from './schema';

const PST_TIMEZONE = 'America/Los_Angeles';

const PickPaymentDate = (props) => {
  const { workflow, validator: { setValues, values, errors, validate, onChangeHandler } } = props;
  const [isValidFirstPaymentDate, setIsValidFirstPaymentDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const params = parseUrlParams(window.location.search);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!params.key || !props.workflow) {
      props.history.push(`/applications/${props.match.params.workflowtype}/application`);
    } else if (!get(props.workflow, 'data.data', get(props.workflow, 'state.data'))) {
      props.history.push(`/applications/${props.match.params.workflowtype}/checkin?key=${params.key}`);
    }
  }, [props.workflow]);

  useEffect(() => {
    setValues({
      ...values,
      firstPaymentDate: moment().tz(PST_TIMEZONE).add(37, 'd'),
    });
  }, []);

  const toggleModal = () => {
    setIsModal(!isModal);
  };

  const handleDateChange = (date) => {
    if (typeof date.format === 'function') {
      onChangeHandler('firstPaymentDate', date.format('MM/DD/YYYY'));
      setIsValidFirstPaymentDate(date.isAfter(moment().tz(PST_TIMEZONE).add(34, 'd')) && date.isBefore(Datetime.moment().add(45, 'd')));
    } else {
      onChangeHandler('firstPaymentDate', date);
      setIsValidFirstPaymentDate(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, match } = props;
    const workflowType = get(match, 'params.workflowType') || 'text2apply';
    if (schema && validate(schema).isValid) {
      setIsLoading(true);
      dispatch(nextAction({
        key: params.key,
        workflowType,
        data: {
          test: params.test,
          FirstPaymentDate: moment(values.firstPaymentDate).format('MM-DD-YYYY'),
        },
        success: (response) => {
          const routeUrl = get(response, 'data.url');
          props.history.push(routeUrl);
          setIsLoading(false);
        },
        fail: (error) => {
          setIsLoading(false);
          console.log(error);
        },
      }));
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="transition-item wrapper v-center">
      <ApplicationId id={get(workflow, 'attributes.entityId')} style={{ marginTop: 10 }} />
      <div className="heading-div bg-white">
        <h2>PLEASE SELECT NEW PAYMENT DATE</h2>
        <div className="info-wrapper">
          <h4>
            The payment date you have previously selected [date] is no longer available.
            <img
              src={infoIcon}
              alt="info icon"
              width={18}
              onClick={toggleModal}
            />
          </h4>
        </div>
        <div className="bg-light pick-payment-date-wrapper">
          <h4>Choose First Payment Date</h4>
          <p>Select a payment date which matches your payroll date.</p>
          <Datetime
            name="firstPaymentDate"
            label="First Payment Date"
            className="dateWrapper"
            value={values.firstPaymentDate}
            defaultValue={Datetime.moment().add(37, 'd')}
            onChange={handleDateChange}
            closeOnSelect
            dateFormat="MM/DD/YYYY"
            displayTimeZone={PST_TIMEZONE}
            timeFormat={false}
            isValidDate={(current) => (
              current.isAfter(Datetime.moment().add(36, 'd'))
                            && current.isBefore(Datetime.moment().add(47, 'd'))
            )}
            placeHolder="MM/DD/YYYY"
            renderInput={(renderProps) => (
              <Input
                isMasked={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                isRequired
                openCalendar={() => { }}
                hasError={!!errors.firstPaymentDate}
                errorMessage={errors.firstPaymentDate}
                label="First Payment Date"
                placeHolder="MM/DD/YYYY"
                labelClassName={cn({ 'iscompleted-data': !!isValidFirstPaymentDate })}
                {...renderProps}
              />
            )}
          />
          <div className="pick-payment-date-action-btn">
            <Button
              color="primary"
              type="button"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </div>
      <Modal isOpen={isModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          Why do I have to change my payment date?
        </ModalHeader>
        <ModalBody>
          <div
            className="mb-0"
            style={{ fontSize: '0.85rem' }}
          >
            There must be at least [X] days between funding and the first payment date.
          </div>
          <div
            className="mb-0"
            style={{ fontSize: '0.85rem' }}
          >
            You will be able to adjust your first payment date by calling our servicing team after your loan has been funded. (needs to be rephrased)
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

PickPaymentDate.propTypes = {
  validator: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,// eslint-disable-line
  nextAction: PropTypes.func.isRequired,
    workflow: PropTypes.object.isRequired,// eslint-disable-line
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fetch: state.fetch,
  workflow: state.workflow,
});

export default Validator(schema)(connect(mapStateToProps)(PickPaymentDate));
