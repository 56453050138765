import moment from 'moment';

export default { firstPaymentDate: [{
  rule: 'required',
  isValid: (input) => {
    if (!input) {
      return 'The input field is required';
    }
    if (!moment(input, 'MM/DD/YYYY').isValid()) {
      return 'First Payment Date is not valid';
    }
    const lowBound = moment().add(37, 'd');
    const upBound = moment().add(47, 'd');
    const inputDate = moment(input, 'MM-DD-YYYY');
    if (moment(inputDate).isBefore(lowBound, 'day') || moment(inputDate).isAfter(upBound, 'day')) {
      return `First Payment Date must be between ${lowBound.format('MM/DD/YYYY')} and ${upBound.format('MM/DD/YYYY')}.`;
    }
    return true;
  },
}] };
