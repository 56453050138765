import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Button } from 'components/Button';
import Validator from 'components/Validator/Validator';
import {
  // Alert,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import {
  nextAction,
  updateOffer,
} from 'actions/workflow';

import { parseUrlParams } from 'utils/parseUrlParams';
import { formatCurrency } from 'utils/formatCurrency';
import genericLogo from 'assets/images/generic-logo.png';
import fslLogo from 'assets/images/fsl-logo.svg';
import schema from './schema';

const UnifiedOfferPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [responseTmp, setResponseTmp] = useState({
    offers: null,
  });
  const [thirdPartyModal, setThirdPartyModal] = useState(false);
  const [isAdvertiserModalVisible, setIsAdvertiserModalVisible] = useState(false);

  const response = useMemo(() => (responseTmp.offers ? responseTmp : get(props.workflow, 'data.data')), [responseTmp]);
  const isOriginationPercentageExist = useMemo(() => {
    const offers = get(response, 'offers', []);
    let result = false;
    offers.forEach((item) => {
      if (Number(item.originationPercentage)) {
        result = true;
      }
    });
    return result;
  }, [response]);
  const hasEvenFinancialOffers = useMemo(() => {
    const offers = get(response, 'offers', []);
    let result = false;
    offers.forEach((item) => {
      if (item.lenderSource === 'Even Financial') {
        result = true;
      }
    });
    return result;
  }, [response]);
  const fslApplication = useMemo(() => get(response, 'fsl.offer'), [response]);
  const isApplicationDeclined = useMemo(() => (!fslApplication && !get(response, 'offers', []).filter((offer) => offer.lenderSource === 'Pagaya').length), [response]);

  const selectOffer = (selectedIndexArg, e) => {
    if (e) e.preventDefault();
    const { workflow } = props;
    const responseClone = { ...response.offers ? response : get(workflow, 'data.data') };

    const params = parseUrlParams(window.location.search);
    const payload = {
      offerName: responseClone.offers[selectedIndexArg].offerName,
    };

    const url = `/workflows/dtc/${params.applicationkey}/next`;
    setIsLoading(true);
    setSelectedIndex(selectedIndexArg);
    props.nextAction({
      data: payload,
      url,
      success: (res) => {
        const offers = [...responseClone.offers];
        const activeOfferIndex = offers.findIndex((_) => _.selected);
        if (activeOfferIndex > -1) offers[activeOfferIndex].selected = false;
        offers[selectedIndexArg].selected = true;
        setIsLoading(false);
        setResponseTmp({ ...responseClone, offers });
        const routeUrl = res.data && res.data.url;
        if (offers[selectedIndexArg] && offers[selectedIndexArg].lenderSource === 'Even Financial') {
          window.open(routeUrl, '_blank');
        } else {
          props.history.push(routeUrl);
        }
      },
      fail: () => {
        setIsLoading(false);
        setSelectedIndex(null);
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = parseUrlParams(window.location.search);

    const { history, workflow, match } = props;
    if (!params.applicationkey && !params.key) {
      history.push(`/applications/${match.params.workflowtype}/application`);
    } else if (get(workflow, 'data.data') === undefined && params.applicationkey) {
      const fslKey = params.fslapplicationkey ? `&fslKey=${params.fslapplicationkey}` : '';

      if (params.offername) {
        history.push(`/applications/${match.params.workflowtype}/checkin?key=${params.applicationkey || ''}&unifiedoffer=true&&offerName=${params.offername}${fslKey}`);
      } else {
        history.push(`/applications/${match.params.workflowtype}/checkin?key=${params.applicationkey || ''}&unifiedoffer=true${fslKey}`);
      }
    } else if (params.offername) {
      const index = get(workflow, 'data.data.offers', []).findIndex((_) => _.offerName === params.offername);
      setSelectedIndex(index);
      setResponseTmp(get(workflow, 'data.data'));

      if (index >= 0) {
        selectOffer(index);
      }
    } else {
      setResponseTmp(get(workflow, 'data.data', { offers: null }));
    }
  }, []);

  const toggleAdvertiserModal = () => {
    setIsAdvertiserModalVisible(!isAdvertiserModalVisible);
  };

  const toggle3rdParyModal = (key) => {
    setThirdPartyModal(!thirdPartyModal);
    setSelectedIndex(!thirdPartyModal ? key : -1);
  };

  return (
    response ? (
      <div className="page-loanoffer">
        <Container>
          {isApplicationDeclined && (
            <Row>
              <Col lg={{ size: 8, offset: 2 }} className="text-center mt-2 mt-md-4">
                <img src="/icons/declined.svg" alt="Declined" className="mb-3" />
                <h2 className="mb-2">Unfortunately, your application was not approved at this time.</h2>
                <p className="mb-4">You will receive an adverse action notice within 30 days that will provide you with the specific reason(s) as to why your application was not approved.</p>
              </Col>
            </Row>
          )}
          <Row className="justify-content-center">
            <Col lg={10} xl={8}>
              {
                get(response, 'offers', []).filter((offer) => offer.lenderSource === 'Pagaya').length ? (
                  <>
                    <Row>
                      <Col className="mb-2 mb-md-4 mt-1 mt-md-3">
                        {!isApplicationDeclined && (
                        <p className="mb-0">
                          <b>Application ID:</b>
                          {' '}
                          {get(props.workflow, 'attributes.entityId')}
                        </p>
                        )}
                        <h1>Select Loan Offer</h1>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <h3 className="border-bottom pb-2 mb-3">Select an offer and confirm amount</h3>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col className="mb-2 mb-md-4 mt-1 mt-md-3 text-center">
                      {!isApplicationDeclined && (
                      <p className="mb-0">
                        <b>Application ID:</b>
                        {' '}
                        {get(props.workflow, 'attributes.entityId')}
                      </p>
                      )}
                      <h1>{fslApplication ? 'Select Option' : 'Other Option'}</h1>
                    </Col>
                  </Row>
                )
              }

              <Row className="mb-4">
                {
                  get(response, 'offers', []).map((item, key) => (
                    <Col xs={12} md={6} key={key}>
                      <Card style={{ backgroundColor: '#d7e7f9' }}>
                        <CardHeader>
                          <h5 className="mb-0 d-flex align-items-center justify-content-center">
                            {item.preApproved ? 'Pre-Approved ' : (item.preQualified ? 'Pre-Qualified ' : '')}
                            {' '}
                            Offer #
                            {key + 1}
                            {item.lenderSource === 'Even Financial' ? ' ‡' : ''}
                          </h5>
                        </CardHeader>
                        <Container fluid className="p-0">
                          <Row noGutters>
                            {
                              item.lenderImageUrl && (
                                <Col xs={12} className="bg-white pt-3 pl-3 pr-3 text-center" style={{ zIndex: 1, position: 'relative' }}>
                                  <div
                                    className="d-flex align-items-start justify-content-center"
                                    style={{ gap: 5 }}
                                  >
                                    <img style={{ height: 40 }} src={item.lenderImageUrl} alt="fsl logo" />
                                  </div>
                                </Col>
                              )
                            }
                            <Col xs={12} className="bg-white pt-3 pl-3 pr-3 text-center" style={{ zIndex: 1, position: 'relative' }}>
                              {
                                !!Number(item.zipTerm) && (
                                  <>
                                    <h2 className="mb-0 text-success text-center" style={{ fontWeight: 500 }}>
                                      {
                                        Number(item.originationPercentage) ? 'NO INTEREST' : '0% APR'
                                      }
                                    </h2>
                                    <h5 className="mb-2">
                                      if paid within
                                      {' '}
                                      {item.zipTerm}
                                      {' '}
                                      months*
                                    </h5>
                                  </>
                                )
                              }
                              <h2 className="mb-0 text-primary text-center">
                                $
                                {formatCurrency(item.paymentAmount, 2) || '-'}
                              </h2>
                              <h5>Est. Monthly Payment</h5>
                              <Button
                                color="primary"
                                className="w-100 mt-2"
                                onClick={selectOffer.bind(null, key)}
                                isLoading={isLoading && key === selectedIndex}
                              >
                                { item.selected ? 'Selected' : 'Select' }
                              </Button>
                            </Col>
                            <Col xs={12} className="bg-white">
                              <Container className="p-3" fluid>
                                <Row className="mb-1" noGutters>
                                  <Col xs={8}>
                                    <h5 className="mb-0">Amount Financed</h5>
                                  </Col>
                                  <Col xs={4}>
                                    <strong>
                                      $
                                      {formatCurrency(item.approvalAmount, 2) || '-'}
                                    </strong>
                                  </Col>
                                </Row>
                                <Row className="mb-1" noGutters>
                                  <Col xs={8}><h5 className="mb-0">Loan Term</h5></Col>
                                  <Col xs={4}>
                                    <strong>
                                      {item.term}
                                      {' '}
                                      Months
                                    </strong>
                                  </Col>
                                </Row>
                                {
                                  item.lenderSource !== 'Even Financial' && (
                                    <Row className="mb-1" noGutters>
                                      <Col xs={8}><h5 className="mb-0">Interest Rate</h5></Col>
                                      <Col xs={4}>
                                        <strong>
                                          {formatCurrency(item.rate, 2) || '-'}
                                          %
                                        </strong>
                                      </Col>
                                    </Row>
                                  )
                                }
                                <Row className="mb-1" noGutters>
                                  <Col xs={8}><h5 className="mb-0">Est. APR</h5></Col>
                                  <Col xs={4}>
                                    <strong>
                                      {formatCurrency(item.apr, 4) || '-'}
                                      %
                                    </strong>
                                  </Col>
                                </Row>
                                {
                                  item.lenderSource !== 'Even Financial' && !!Number(item.originationPercentage) && (
                                    <Row className="mb-1" noGutters>
                                      <Col xs={8}>
                                        <h5 className="mb-0">Origination Fee**</h5>
                                        <small>Included in monthly payment</small>
                                      </Col>
                                      <Col xs={4}>
                                        <strong>
                                          {Number(item.originationPercentage) ? formatCurrency(Number(item.originationPercentage) * 100, 2) : '0'}
                                          %
                                        </strong>
                                      </Col>
                                    </Row>
                                  )
                                }
                              </Container>
                            </Col>
                            {item.lenderSource === 'Even Financial' && (
                              <Col xs={12} className="bg-white text-center">
                                <Container className="pb-3" fluid>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      color: '#3989E3',
                                      textDecoration: 'underline',
                                      lineHeight: '18px',
                                    }}
                                  >
                                    <span style={{ cursor: 'pointer' }} onClick={toggle3rdParyModal.bind(this, key)}>
                                      Disclaimers & More Info
                                    </span>
                                    <br />
                                    <span style={{ cursor: 'pointer' }} onClick={toggleAdvertiserModal}>
                                      Advertiser Disclosure
                                    </span>
                                  </div>
                                </Container>
                              </Col>
                            )}
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  ))
                }
                {fslApplication ? (
                  <Col xs={12} md={6}>
                    <Card style={{ backgroundColor: '#d7e7f9' }}>
                      <CardHeader>
                        <h5 className="mb-0">Debt Relief</h5>
                      </CardHeader>
                      <Container fluid className="p-0">
                        <Row noGutters>
                          <Col xs={12} className="bg-white pt-3 pl-3 pr-3 text-center" style={{ zIndex: 1, position: 'relative' }}>
                            <img src={fslLogo} alt="fsl logo" />
                          </Col>
                          <Col xs={12} className="bg-white">
                            <div className="w-100 enrollment-call-btn">
                              Enrollment required
                              <span style={{ fontWeight: 500, color: '#d72028' }}>
                                &#8224;
                              </span>
                            </div>
                            <Container className="p-3" fluid>
                              <ul className="mb-1" noGutters>
                                <li className="mb-1">
                                  <h5 className="mb-0">Pre-Approved!</h5>
                                </li>
                                <li className="mb-1">
                                  <h5 className="mb-0">No Interest if Paid Within Three (3) Months*</h5>
                                </li>
                                <li className="mb-1">
                                  <h5 className="mb-0">No Prepayment Penalties</h5>
                                </li>
                                <li className="mb-1">
                                  <h5 className="mb-0">Down Payment Required</h5>
                                </li>
                                <li className="mb-1">
                                  <h5 className="mb-0">Loan Includes an Origination Fee**</h5>
                                </li>
                              </ul>
                              <Row className="mt-2">
                                <Col>
                                  <p style={{ fontSize: '.8rem', textAlign: 'center', color: '#d72028', fontWeight: 500 }}>&#8224;Enrollment required with a participating debt relief company***</p>
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </Col>
                ) : (
                  <Col xs={12} md={{ size: 6, offset: !fslApplication && !get(response, 'offers', []).length ? 3 : 0 }}>
                    <Card style={{ backgroundColor: '#d7e7f9' }}>
                      <CardHeader>
                        <h5 className="mb-0">Debt Relief</h5>
                      </CardHeader>
                      <Container fluid className="p-0">
                        <Row noGutters>
                          <Col xs={12} className="bg-white pt-3 pl-3 pr-3 text-center" style={{ zIndex: 1, position: 'relative' }}>
                            <img src={get(response, 'clientTerms.logoUrl') || genericLogo} alt="alleviate logo" />
                          </Col>
                          <Col xs={12} className="bg-white">
                            <Container className="p-3" fluid>
                              <ul className="mb-1" noGutters>
                                {get(response, 'clientTerms.terms') ? get(response, 'clientTerms.terms').map((term, idx) => (
                                  <li key={idx} className="mb-1">
                                    <h5 className="mb-0">{term}</h5>
                                  </li>
                                )) : (
                                  <>
                                    <li className="mb-1">
                                      <h5 className="mb-0">Flexible Monthly Program Terms</h5>
                                    </li>
                                    <li className="mb-1">
                                      <h5 className="mb-0">Control Your Program Options</h5>
                                    </li>
                                    <li className="mb-1">
                                      <h5 className="mb-0">
                                        &lt;Trustpilot rating&gt;
                                        <sup>1</sup>
                                      </h5>
                                    </li>
                                    <li className="mb-1">
                                      <h5 className="mb-0">
                                        &lt;BBB rating&gt;
                                        <sup>1</sup>
                                      </h5>
                                    </li>
                                  </>
                                )}
                              </ul>
                              <p className="mt-2">
                                {get(response, 'clientTerms.disclosure')
                                  ? get(response, 'clientTerms.disclosure')
                                  : (
                                    <>
                                      <sup>1</sup>
                                      As of &lt;date&gt;
                                    </>
                                  )}

                              </p>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10} xl={8} className="text-center">
              {fslApplication && (
                <p style={{ fontSize: '.8rem' }}>
                  Terms are subject to change based on the first payment date that you will select.
                </p>
              )}
              {
                fslApplication
                && (
                  <p style={{ fontSize: '.8rem' }}>
                    * Your loan may have a No Interest on Principal Option Promotion included. This promotion can save you money if you pay off the principal amount of the loan in full within the Promotional Period (&#34;Promotional Period&#34;). During the Promotional Period you will be responsible for making all of your monthly payments and your loan will accrue interest on a monthly basis. If you pay off your loan within the Promotional Period, the monthly payments that you have made during this period, which includes accrued interest, will be deducted from the principal amount of the loan. Length of Promotional Periods vary, please review your loan agreement for full details.
                  </p>
                )
              }
              {
                (fslApplication || (isOriginationPercentageExist && get(response, 'offers', []).filter((offer) => offer.lenderSource === 'Pagaya').length)) ? (
                  <p style={{ fontSize: '.8rem' }}>
                    ** The Origination Fee may be up to 8% of the Amount Financed, is non-refundable, and is considered earned upon the funding of your loan. You can calculate the dollar amount of the Origination Fee for each offer listed above by multiplying the Amount Financed figure for that offer by .08 if the Origination Fee is 8%, by .05 if it is 5%, etc. For example, if the Origination Fee is 8% and if the Amount Financed is $1,000 the corresponding Origination Fee would be $80 ($1000 x .08 = $80). The total Principal Amount of your loan is the sum of the Amount Financed and the Origination Fee (i.e. if the Origination Fee is 8% and if the Amount Financed is $1,000 the Principal Amount of the loan would be $1080). Please review your Loan Agreement for additional terms and conditions.
                  </p>
                ) : null
              }
              {
                fslApplication && (
                  <p style={{ fontSize: '.8rem' }}>*** LendingUSA may receive compensation from the debt relief company related to this referral.</p>
                )
              }
              {
                hasEvenFinancialOffers && (
                  <p style={{ fontSize: '.8rem' }}>
                    ‡ This is not a loan through LendingUSA made by its
                    {' '}
                    <a href="https://lendingusa.com/lending-partners/" target="_blank" rel="noopener noreferrer">lending partners</a>
                    . The offers that appear are from companies from which LendingUSA and Engine by MoneyLion and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed above. However, this compensation also facilitates the provision by LendingUSA and Engine by MoneyLion of certain services to you at no charge. All rates, fees, and terms are presented without guarantee and are subject to change pursuant to each provider’s discretion and may not be available in all states or for all types of loans. There is no guarantee you will be approved or qualify for the advertised rates, fees, or terms presented. This website does not include all lending companies or all available lending offers that may be available to you.
                  </p>
                )
              }
            </Col>
          </Row>
          <Modal isOpen={thirdPartyModal} toggle={toggle3rdParyModal} size="lg">
            <ModalHeader toggle={toggle3rdParyModal}>
              {get(response, `offers.${selectedIndex}.offerName`)}
            </ModalHeader>
            <ModalBody style={{ maxHeight: 600, overflow: 'auto' }}>
              <div
                style={{ fontSize: '0.875rem' }}
                dangerouslySetInnerHTML={{
                  __html: get(response, `offers.${selectedIndex}.lenderDescription`),
                }}
              />
              <div
                className="mb-0"
                style={{ fontSize: '0.85rem', textAlign: 'center' }}
                dangerouslySetInnerHTML={{
                  __html: get(response, `offers.${selectedIndex}.lenderDisclaimer`)
                    && get(response, `offers.${selectedIndex}.lenderDisclaimer`)
                      .replaceAll('\n', "<br style='content: \"\"; height: 0.5rem; display: block;'/>"),
                }}
              />
              <div
                className="mb-0 offer-modal-disclaimer"
                style={{ fontSize: '0.85rem', textAlign: 'center' }}
              >
                <br />
                Annual Percentage Rate (APR) Disclosure: The Annual Percentage Rate is the rate at which your loan accrues interest. It is based upon the amount of your loan, the cost of the loan, term of the loan, repayment amounts and timing of payments and payoff. By law, the lender or lending partner must show you the APR before you enter into the loan. States have laws that may limit the APR that the lender or lending partner can charge you. Rates will vary based on your credit worthiness, loan size, amongst other variables, with the lowest rates available to customers with excellent credit. Minimum and maximum loan amounts and APRs may vary according to state law and lender or lending partner. We recommend that you read the lender’s and/or lending partner’s personal terms and conditions in full before proceeding for any loan.
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={isAdvertiserModalVisible} toggle={toggleAdvertiserModal} size="lg">
            <ModalHeader toggle={toggleAdvertiserModal}>
              Advertiser Disclosure
            </ModalHeader>
            <ModalBody style={{ maxHeight: 600, overflow: 'auto' }}>
              <div
                style={{ fontSize: '0.875rem' }}
                dangerouslySetInnerHTML={{
                  __html: get(response, `offers.${selectedIndex}.lenderDescription`),
                }}
              />
              <div
                className="mb-0"
                style={{ fontSize: '0.85rem', textAlign: 'center' }}
              >
                The offers that appear are from companies which LendingUSA and Engine by MoneyLion and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed below. However, this compensation also facilitates the provision by LendingUSA and Engine by MoneyLion of certain services to you at no charge. The offers displayed in your results do not include all financial services companies or all of their available product and service offerings.
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    ) : <div />
  );
};

UnifiedOfferPage.propTypes = {
  history: PropTypes.object.isRequired,
  nextAction: PropTypes.func.isRequired,
  workflow: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  validator: PropTypes.object.isRequired,
};

export default compose(
  Validator(schema),
  withRouter,
  connect(
    (state) => ({
      workflow: state.workflow,
    }),
    {
      nextAction,
      updateOffer,
    }
  )
)(UnifiedOfferPage);
