import { all } from 'redux-saga/effects';
import workflowSaga from './workflowSaga';
import merchantSearchSaga from './merchantSearchSaga';
import lookupSagas from './lookupSagas';

const Sagas = function* () {
  yield all([
    workflowSaga(),
    merchantSearchSaga(),
    lookupSagas(),
  ]);
};

export default Sagas;
