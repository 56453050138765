import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import get from 'lodash/get';

import {
  Col,
  Container,
  Row,
  Button,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';
import circleDeclined from 'assets/icons/circle-declined.svg';
import style from './style.scss';

const GeneralErrorPage = (props) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleMerchantReturnClick = (e) => {
    e.preventDefault();
    const params = parseUrlParams(window.location.search);
    if (params.pid) {
      props.history.push(`/?pid=${params.pid}`);
    } else {
      props.history.push('/');
    }
  };

  useEffect(() => {
    if (get(props.location, 'data.failure.errors.0.message') !== undefined) {
      setErrorMessage(props.location.data.failure.errors[0].message.join(','));
    }
  }, [props.location]);

  return (
    <Container>
      <Row>
        <Col xs={12} className="text-center">
          <img src={circleDeclined} alt="Circle Declined" />
          <Row className="text-center mt-2">
            <Col xs={{ size: 8, offset: 2 }}>
              <h2>Unfortunately, we were unable to process your application at this time</h2>
            </Col>
          </Row>
          {
            errorMessage && <p className={cn('p-xlarge', style.error)}>{errorMessage}</p>
          }
          {
            localStorage.getItem('token') && (
              <Button
                color="primary"
                size="lg"
                className="mt-4 large arrow"
                onClick={handleMerchantReturnClick}
              >
                Return to Check my rate page
              </Button>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};

GeneralErrorPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

GeneralErrorPage.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
}))(GeneralErrorPage);
