import get from 'lodash/get';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

import { appConfig } from 'config/appConfig';

const getSmartyClientBuilder = (licences) => {
  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const credentials = new SmartyStreetsCore.SharedCredentials(appConfig.smartyStreetsWebsiteKey);
  const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);

  if (licences) {
    return clientBuilder.withLicenses(licences);
  }
  return clientBuilder;
};

export const verifyAddress = ({ zipcode, state, city, address }) => {
  const { usStreet: { Lookup } } = SmartyStreetsSDK;

  const client = getSmartyClientBuilder().buildUsStreetApiClient();
  const lookup = new Lookup();
  if (address) {
    lookup.street = address;
  }

  if (zipcode) {
    lookup.zipCode = zipcode;
  }

  if (state) {
    lookup.state = state;
  }

  if (city) {
    lookup.city = city;
  }

  lookup.maxCandidates = 10;

  return new Promise((resolve, reject) => {
    client.send(lookup)
      .then((response) => {
        resolve(get(response, 'lookups[0].result[0]'));
      })
      .catch((response) => {
        reject(response);
      });
  });
};
