import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'reactstrap';
import cn from 'classnames';
import comodo from 'assets/images/comodo.png';

const Footer = ({ pageName, isContentVisible }) => (
  // Global Footer
  <div id="Footer" className="footer-container footer-simple" data-sticky-footer>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="footer">
        {
          isContentVisible && (
            <div className="footer-content" style={{ fontSize: '.8rem', margin: 30 }}>
              <p style={{ marginBottom: 15 }}>
                All loans through LendingUSA are made by its
                {' '}
                <a href="https://lendingusa.com/lending-partners/" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">lending partners</a>
                . Amounts financed range from $1,000 to $47,500 but vary by term, state, and loan purpose. Loans are not available in all states. An origination fee of up to 8% may be included in the principal loan amount that may result in an APR of up to 29.99%.
              </p>
            </div>
          )
        }
        <Row className={cn('legal', !isContentVisible && 'mb-0 mt-0 pt-0 pb-0', pageName === 'creditOfferConfirmation' ? 'creditOfferConfirmation' : '')} style={{ borderTop: isContentVisible ? '1px solid #f2f1f0' : 'none' }}>
          <Col className="medium-6 security">
            <img src={comodo} alt="Equal Housing Opportunity - Comodo" className="comodoImage" />
            <div className="footer-copy">
              <p>
                Copyright ©
                {' '}
                {new Date().getFullYear()}
                . LendingUSA, LLC. All Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
  // End Global Footer
);

Footer.propTypes = {
  pageName: PropTypes.string,
  isContentVisible: PropTypes.bool,
};

Footer.defaultProps = {
  pageName: '',
  isContentVisible: false,
};

export default Footer;
