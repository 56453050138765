import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import Application from './containers/App';
import configureStore from './store';
import reportWebVitals from './reportWebVitals';
import './assets/styles/global.scss';

const history = createBrowserHistory();
const store = configureStore({

}, history);

const render = () => {
  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
          <Application />
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>
  );
};

setTimeout(() => render(), 1200);
reportWebVitals();
