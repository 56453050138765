import { WorkflowConstants } from 'constants/workflowConstants';
import { createAction } from 'redux-actions';

export const checkinAction = createAction(WorkflowConstants.CHECKIN_ACTION);
export const nextAction = createAction(WorkflowConstants.NEXT_ACTION);
export const updateOffer = createAction(WorkflowConstants.UPDATE_OFFER);
export const checkPreviousAction = createAction(WorkflowConstants.PREVIOUS_ACTION);
export const getIPAddress = createAction(WorkflowConstants.GET_IP_ADDRESS);
export const getLoanPricingPromo = createAction(WorkflowConstants.GET_LOAN_PRICING_PROMO);
export const saveApplicationForm = createAction(WorkflowConstants.SAVE_APPLICATION_FORM);
export const getAffDetails = createAction(WorkflowConstants.GET_AFF_DETAILS);
export const getKeyAction = createAction(WorkflowConstants.GET_KEY_ACTION);
export const checkDuplication = createAction(WorkflowConstants.CHECK_DUPLICATION);
export const resumeApplicationAction = createAction(WorkflowConstants.RESUME_APPLICATION);
export const validateRoutingNumberAction = createAction(WorkflowConstants.VALID_ROUTING_NUMBER);
