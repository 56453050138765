import keyMirror from 'keymirror';

/**
 * @constant {Object} ApplicationConstants
 * @memberof Application
 */
export const WorkflowConstants = keyMirror({
  CHECKIN_ACTION: undefined,
  NEXT_ACTION: undefined,
  PREVIOUS_ACTION: undefined,
  GET_IP_ADDRESS: undefined,
  UPDATE_OFFER: undefined,
  GET_LOAN_PRICING_PROMO: undefined,
  SAVE_APPLICATION_FORM: undefined,
  GET_AFF_DETAILS: undefined,
  GET_KEY_ACTION: undefined,
  CHECK_DUPLICATION: undefined,
  RESUME_APPLICATION: undefined,
  VALID_ROUTING_NUMBER: undefined,
});
