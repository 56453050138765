import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SecurityLock from 'components/Icons/SecurityLock';

import comodo from 'assets/images/comodo.png';
import style from './style.scss';

const Heading = ({ heading, subHeading, isCardVisible, size, className }) => (
  // Heading
  <div className={`cell small-12 form-headline ${className}`}>
    <h2 className={cn(style.heading, className)}>{heading}</h2>
    <p className={size}>{subHeading}</p>
    {
      isCardVisible && (
        <div className="grid-x security hide-for-large">
          <div className={cn('cell small-9 ssl', style.encryption)}>
            <SecurityLock width="28" className={style.securityLock} />
            <p className={cn('p-small', style.noBottomMargin)}>
              <strong>128-bit SSL</strong>
              {' '}
              protection and strict encryption
            </p>
          </div>
          <div className="cell small-3 certificate">
            <img src={comodo} alt="" className="setigo-image" />
          </div>
        </div>
      )
    }
  </div>
  // End Heading
);

Heading.propTypes = {
  heading: PropTypes.any,
  subHeading: PropTypes.any,
  isCardVisible: PropTypes.bool,
  size: PropTypes.any,
  className: PropTypes.string,
};

Heading.defaultProps = {
  heading: '',
  subHeading: '',
  isCardVisible: false,
  size: 'p-large',
  className: '',
};

export default Heading;
