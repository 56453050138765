import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Column = ({ className, children }) => (
  <div className={cn('cell', className)}>
    {children}
  </div>
);

Column.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Column.defaultProps = {
  className: '',
  children: '',
};

export default Column;
