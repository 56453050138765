import moment from 'moment';
import get from 'lodash/get';

import { numberUnmask, removeSpace, floatUnmask } from 'utils/masks';
import { validateEmail } from 'utils/helper';
import { MIN_LOAN_AMOUNT, MAX_LOAN_AMOUNT } from 'utils/loanAmounts';

const Schema = {
  requestedAmount: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return 'The input field is required';
      }

      return ((Number(floatUnmask(input)) < MIN_LOAN_AMOUNT) || Number(floatUnmask(input)) > MAX_LOAN_AMOUNT) ? 'Loan Amount should be between $1,000.00 and $47,500.00' : true;
    },
  }],
  purpose: [{
    rule: 'required',
    error: 'The input field is required',
  }],
  'applicant.firstName': [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  'applicant.lastName': [{
    rule: 'required',
    isValid: (input) => {
      if (!input || removeSpace(input).length === 0) {
        return 'The input field is required';
      }
      return true;
    },
  }],
  'applicant.email': [
    {
      rule: 'required',
      isValid: (input) => {
        if (!input) {
          return 'The input field is required';
        }

        const blokingEmails = [
          'none@none.com',
          'noemail@noemail.com',
          'noemail@gmail.com',
          'noemail@email.com',
          'none@noemail.COM',
          'none@gmail.com',
          'none@yahoo.com',
          'no@no.com',
          'no@email.com',
          'noemail@yahoo.com',
          'test@test.com',
          'na@na.com',
          'noname@gmail.com',
          'no@gmail.com',
          'noemail@mail.com',
          'test@email.com',
          'unknown@yahoo.com',
          'non@non.com',
          'noemal@noemail.com',
          'none@email.com',
          'noname@email.com',
          'noemail@no.com',
          'non@none.com',
          'email@email.com',
          'nomail@gmail.com',
          'noemail@gimail.com',
          'noemail@aol.com',
          'na@gmail.com',
          'noemail@gmai.com',
          'noemail@noemai.com',
          'none@aol.com',
          'na@na.com',
          'noemail@aol.com',
        ];

        return (
          (blokingEmails.indexOf(`${input}`.toLowerCase()) !== -1)
          || !validateEmail(input)
        ) ? 'Email is not valid' : true;
      },
    },
  ],
  'applicant.ssn': [
    {
      rule: 'required',
      isValid: (input) => {
        if (!input) {
          return 'The input field is required';
        }

        if (numberUnmask(input).length < 9) {
          return 'SSN is not valid';
        }

        const blockingSsns = [
          '078-05-1120',
          '219-09-9999',
          '111-11-1111',
          '222-22-2222',
          '333-33-3333',
          '444-44-4444',
          '555-55-5555',
          '666-66-6666',
          '777-77-7777',
          '888-88-8888',
          '999-99-9999',
          '123-45-6789',
        ];

        if (
          numberUnmask(input).substring(0, 3) === '000'
          || numberUnmask(input).substring(3, 5) === '00'
          || numberUnmask(input).substring(5, 9) === '0000'
          || (Number(numberUnmask(input).substring(0, 3)) > 899 && Number(numberUnmask(input).substring(0, 3)) < 1000)
          || blockingSsns.includes(input)
        ) {
          return 'SSN is not valid';
        }
      },
    },
  ],
  'applicant.dateOfBirth': [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return 'The input field is required';
      }

      if (numberUnmask(input).length < 8) {
        return 'Date of Birth is not valid';
      }

      if (!moment(input, 'MM/DD/YYYY').isValid()) {
        return 'Date of Birth is not valid';
      }

      if (moment().diff(moment(input, 'MM-DD-YYYY'), 'years') < 18) {
        return 'Must be over 18 years old';
      }

      if (parseInt(input.slice(-4), 10) < 1900) {
        return 'Please confirm date of birth';
      }
      return true;
    },
  }],
  'applicant.address.address': [{
    rule: 'required',
    error: 'The input field is required',
  }],
  'applicant.address.city': [{
    rule: 'required',
    error: 'The input field is required',
  }],
  'applicant.address.state': [{
    rule: 'required',
    error: 'The input field is required',
  }],
  'applicant.address.zipcode': [{
    rule: 'required',
    error: 'The input field is required',
  }],
  'applicant.phoneNumber.number': [{
    rule: 'required',
    isValid: (input) => {
      const phoneNumbers = [
        '(111) 111-1111',
        '(222) 222-2222',
        '(333) 333-3333',
        '(444) 444-4444',
        '(555) 555-5555',
        '(666) 666-6666',
        '(777) 777-7777',
        '(999) 999-9999',
      ];

      if (!input) {
        return 'The input field is required';
      }

      return (
        numberUnmask(input).length < 10
        || phoneNumbers.indexOf(input || '') !== -1
        || (input && input.charAt(1) === '0')
        || (input && input.charAt(1) === '1')
        || (input && input.substring(6) === '000-0000')
      ) ? 'Phone number is not valid' : true;
    },
  }],
  'financials.stated.employmentStatus': [{
    rule: 'required',
    error: 'The input field is required',
  }],
  'financials.stated.grossMonthlyIncome': [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return 'The input field is required';
      }

      return (Number(floatUnmask(input)) > 41600 || Number(floatUnmask(input)) < 1) ? 'The amount should not exceed $41,600.' : true;
    },
  }],
  ClientId: [{
    rule: 'required',
    isValid: (input, err, opt, formData) => {
      const purpose = get(formData, 'purpose');

      if (purpose === 'funeral' && (!input || !`${input}`.trim())) {
        return 'The Decedent Name field is required';
      }

      return true;
    },
  }],
  allConsent: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return (
          'Please check the box'
        );
      }
    },
  }],
  consentToTermsAndConditions: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return (
          'Please check the box'
        );
      }
    },
  }],
  consentToThirdParty: [{
    rule: 'required',
    isValid: (input) => {
      if (!input) {
        return (
          'Please check the box'
        );
      }
    },
  }],
};

export default Schema;
