import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import { parseUrlParams } from 'utils/parseUrlParams';
import './style.scss';

import fslLogo from 'assets/images/fsl-logo.svg';

const FSLDeclined = (props) => {
  useEffect(() => {
    const params = parseUrlParams(window.location.search);

    if (!params.key) {
      props.history.push(`/applications/${props.match.params.workflowtype}/application`);
    }
  }, []);

  return (
    <div className="page-apply">
      <Container>

        <Row className="mb-3 mt-4">
          <Col xs={12} md={9} lg={5} className="text-center ml-auto mr-auto">
            <h3 className="ml-auto mr-auto mb-2">Unfortunately, you are not approved for a loan at this time.</h3>
            <p className="mb-1">An adverse action notice will be sent within 30 days.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={9} lg={5} className="text-center ml-auto mr-auto mb-5 another-option">
            <h2 className="mb-3 text-center">
              <u>But wait!</u>
              <br />
              {' '}
              We have another option:
            </h2>
            <h4 className="mb-4 mt-1">Freshstart Lending and its partners may be able to help you consolidate your debt.</h4>
            <h4 className="mb-1 mt-1">Call now for a free consultation!</h4>
            <h1 className="mb-3"><a href="tel:8772209145" className="cta-arrows text-danger">(877) 220-9145</a></h1>
            <img src={fslLogo} alt="Personify Financial" width="85%" className="mb-4" />
          </Col>
        </Row>

      </Container>
    </div>
  );
};

FSLDeclined.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

FSLDeclined.defaultProps = {
};

export default connect((state) => ({
  auth: state.auth,
}))(FSLDeclined);
